import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GetStudentList, studentListSelector } from "../../features/slice";
import Select from "react-select";
import { toast } from "react-toastify";
import { useCallback } from "react";
import Loader from "../Loader";
function AddEventToCart({
  show,
  handleShow,
  done,
  submitting,
  // studentList,
  selectedLevel,
  eventData,
  setSubmitting,
}) {
  console.log(selectedLevel, "Selected level");
  const dispatch = useDispatch();
  const [selectedStudent, setSelectedStudent] = useState([]);

  const studentList = useSelector(studentListSelector);

  const getStudentList = useCallback(() => {
    dispatch(
      GetStudentList({
        eventId: eventData?.id ? eventData?.id : "",
        levelId: selectedLevel?.id,
      })
    );
  }, [dispatch, eventData?.id, selectedLevel?.id]);

  useEffect(() => {
    getStudentList();
  }, [getStudentList]);

  return (
    <Modal
      show={show}
      onHide={() => {
        handleShow();
      }}
      size="lg"
      centered
    >
      <Modal.Body>
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content other-city b-r-20">
            <div className="modal-header text-center">
              <div>
                <h2 className="mdl-ttl">Select Student</h2>
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleShow}
              ></button>
            </div>
            <div className="modal-body">
              {studentList
                ?.filter(
                  (it) =>
                    (it?.discipline?.toString() ===
                      eventData?.discipline_details?.id?.toString() &&
                      it?.student_type?.toLowerCase() ===
                        eventData?.age_group?.toLowerCase() &&
                      it?.trialAccountStatus === "3") ||
                    (it?.discipline?.toString() ===
                      eventData?.discipline_details?.id?.toString() &&
                      eventData?.age_group?.toLowerCase() === "both" &&
                      it?.trialAccountStatus === "3")
                )
                ?.filter((it) => !it?.isAddedInCart && !it?.isBooked)?.length >
              0 ? (
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                    <Select
                      value={selectedStudent || undefined}
                      // isMulti
                      name="colors"
                      options={
                        studentList
                          ?.filter(
                            (it) =>
                              (it?.discipline?.toString() ===
                                eventData?.discipline_details?.id?.toString() &&
                                it?.student_type?.toLowerCase() ===
                                  eventData?.age_group?.toLowerCase() &&
                                it?.trialAccountStatus === "3") ||
                              (it?.discipline?.toString() ===
                                eventData?.discipline_details?.id?.toString() &&
                                eventData?.age_group?.toLowerCase() ===
                                  "both" &&
                                it?.trialAccountStatus === "3")
                          )
                          ?.filter(
                            (it) => !it?.isAddedInCart && !it?.isBooked
                          ) || undefined
                      }
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) =>
                        `${option.first_name} ${option.last_name}`
                      }
                      onChange={(val) => {
                        if (
                          eventData?.age_group.toLowerCase() ===
                            val?.student_type ||
                          eventData?.age_group.toLowerCase() === "both"
                        ) {
                          setSelectedStudent(val);
                        } else {
                          toast.error(
                            `${
                              val?.student_type
                            } can not book event for ${eventData?.age_group.toLowerCase()}`
                          );
                        }
                      }}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 mt-3 text-center">
                    <button
                      className="btn btn-accept btn-view mt-2 text-center"
                      style={{
                        width: "150px",
                      }}
                      disabled={submitting}
                      onClick={() => {
                        if (selectedStudent) {
                          setSubmitting(true);
                          done(selectedStudent);
                        } else {
                          toast.error("Kindly select a student for the event.");
                        }
                      }}
                    >
                      {submitting ? <Loader /> : "Done"}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="row text-center">
                  <h5>No student available of same event category.</h5>
                  <div className="col-sm-12 col-md-12 col-lg-12 mt-3 text-center">
                    <button
                      className="btn btn-accept btn-view mt-2 text-center"
                      style={{
                        width: "150px",
                      }}
                      onClick={() => {
                        handleShow();
                      }}
                    >
                      Back
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AddEventToCart;
