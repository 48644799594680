import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "../../utils/schema";
import { loginCall } from "../slice";
import { toast } from "react-toastify";
import Loader from "../../component/Loader";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    formState,
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    resolver: yupResolver(loginSchema),
    shouldFocusError: true,
  });

  const { isSubmitted } = formState;

  // useEffect(() => {
  //   localStorage.clear();
  // }, []);

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(
      loginCall({
        email: data?.email?.trim()?.toLowerCase(),
        password: data?.password,
        user_type: "user",
      })
    ).then(async (res) => {
      setLoading(false);

      if (res?.payload?.code === 1) {
        await localStorage.setItem("token", res?.payload?.data?.token);
        await localStorage.setItem(
          "user",
          JSON.stringify(res?.payload?.data?.user)
        );
        navigate("/dashboard", { replace: true });
        toast.success(res?.payload?.message);
      } else {
        if (res?.payload?.data?.user) {
          await localStorage.setItem("token", res?.payload?.data?.token);
          await localStorage.setItem(
            "user",
            JSON.stringify(res?.payload?.data?.user)
          );
        }
        const user = res?.payload?.data?.user;
        toast.error(res?.payload?.message);

        if (user?.profile_stag === "0") {
          // create account complete go to otp page
          navigate("/create-account", { replace: true });
        } else if (user?.profile_stag === "1") {
          // otp is complete go to address page
          navigate("/address", { replace: true });
        } else if (user?.profile_stag === "2") {
          // address is complete go to add student detail page
          navigate(`/add-student-details`, {
            replace: true,
          });
        } else if (user?.profile_stag === "3") {
          // add student detail is complete go to membership modal
          navigate(`/add-student-details`, {
            replace: true,
          });
        }
      }
    });
  };

  return (
    <section className="bg-image">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="logo-bg position-relative">
              <p>
                <img
                  src="assets/img/headerlogo.png"
                  className="img-fluid cursor-pointer"
                  alt="logo"
                  onClick={() => {
                    navigate("/", { replace: true });
                  }}
                />
              </p>
              <p>
                <img
                  src="assets/img/banner_logo.png"
                  className="logo-img"
                  alt="logo"
                />
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 ">
            <div className="form-group col-sm-12 col-md-12 text-end">
              <span
                className="btn-teacher fw-bold fs-5 cursor-pointer"
                onClick={() => {
                  window.open("https://teacher.bookinclass.co.uk/", "_self");
                  // window.open(
                  //   "https://bookinclass-teacher.vercel.app/",
                  //   "_self"
                  // );
                }}
              >
                Teacher Access
              </span>
            </div>
            <div className="form-style mt-5">
              <form className="row" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                  <h2 className="mdl-ttl">log in</h2>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="fw-bold">Email ID / Username</label>
                  <div className="input-container">
                    <input
                      type="text"
                      className="form-control ps-4"
                      placeholder="Email Address or Username"
                      name="email"
                      {...register("email")}
                    />
                    <img
                      src="assets/img/email.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                  {errors?.email && (
                    <div className="text-red">{errors?.email?.message}</div>
                  )}
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="fw-bold">Password</label>

                  <div className="input-container">
                    <input
                      id="password-field"
                      type={toggle ? "text" : "password"}
                      className="form-control ps-4"
                      placeholder="Password"
                      name="password"
                      {...register("password")}
                    />
                    <img
                      src="assets/img/password.png"
                      className="input-img pass"
                      alt=""
                    />
                    <span
                      onClick={() => {
                        setToggle(!toggle);
                      }}
                      className="pass-view field-icon toggle-password cursor-pointer"
                    ></span>
                  </div>
                  {errors?.password && (
                    <div className="text-red">{errors?.password?.message}</div>
                  )}
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                  <div className="input-container">
                    <p>
                      <Link
                        to="/forget-password"
                        className="theme-color fw-bold"
                      >
                        Forgot Password?
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 text-center">
                  <button
                    type="submit"
                    className="btn btn-submit btn-animated"
                    disabled={loading}
                  >
                    {loading ? <Loader /> : "Log In"}
                  </button>
                </div>

                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                  <div className="input-container">
                    <p>
                      Don't have an account?{" "}
                      <Link
                        to="/create-account"
                        className="theme-color fw-bold"
                      >
                        Create Account
                      </Link>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
