import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { config, htmlStringToPdf, responsive } from "../utils";
import {
  FetchDefaultEventList,
  getEventLists,
  setEventDetail,
} from "./event/EventSlice";
import { useNavigate } from "react-router";
import { useToggle } from "./../utils/hooks";
import { FetchUserDetail, userDetailSelector } from "./slice";
import moment from "moment";
import { consentList } from "../api/authentications";
import { fetchToken, onMessageListener } from "../firebase";
import { toast } from "react-toastify";
import S3FileUpload from "react-s3";
function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const eventList = useSelector(getEventLists);
  const [studentId, setStudentId] = useState("");
  const detail = useSelector(userDetailSelector);
  const [show, toggle] = useToggle();
  const [consents, setConsents] = useState([]);
  const [defaultConsentList, setDefaultConsentList] = useState([]);

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    return (
      <div className="carousel-button-group top-0" style={{ right: 20 }}>
        <button
          onClick={() => previous()}
          className={"slick-prev slick-arrow"}
          aria-label="Previous"
          type="button"
        >
          Previous
        </button>
        <button
          onClick={() => next()}
          className="slick-next slick-arrow"
          aria-label="Next"
          type="button"
        >
          Next
        </button>
      </div>
    );
  };

  useEffect(() => {
    dispatch(FetchDefaultEventList({ page: 1, limit: 1000, studentId: "" }));
    dispatch(FetchUserDetail({}));
  }, [dispatch]);

  return (
    <>
      <section className="sec-bg py-4 px-3 bottom-space">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-8 col-xl-9 mb-3">
              <div className="row align-items-center">
                <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                  <h5>Upcoming Special Events</h5>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 mt-3 position-relative">
                  <div className="owl-slider Studentevent-slider student-container ">
                    {eventList?.length > 0 ? (
                      <>
                        <Carousel
                          responsive={responsive}
                          renderButtonGroupOutside={true}
                          customButtonGroup={<ButtonGroup />}
                          arrows={false}
                        >
                          {eventList?.map((item, i) => (
                            <div className="mx-2" key={i}>
                              <div className="class__details bg-white p-2 rounded h-100 position-relative">
                                <div className="event-box-flex align-items-normal p-0">
                                  <span>
                                    <img
                                      src={
                                        item?.image?.includes("http")
                                          ? item?.image
                                          : "assets/img/headerlogo.png"
                                      }
                                      className="event-img"
                                      alt=""
                                    />
                                  </span>
                                  <span className="truncate">
                                    <h6 className="truncate">
                                      {item?.name || "NA"}
                                    </h6>
                                    <p className="mb-0 fs-7 truncate">
                                      <img
                                        src="assets/img/address.png"
                                        className=""
                                        alt=""
                                      />{" "}
                                      {item?.school_details?.name || "NA"}
                                    </p>
                                    <p className="mb-0 fs-7">
                                      <img
                                        src="assets/img/date-of-birth.png"
                                        className=""
                                        alt=""
                                      />{" "}
                                      {item?.date
                                        ? moment(item?.date).format(
                                            "DD MMM YYYY"
                                          )
                                        : "NA"}
                                    </p>
                                  </span>
                                </div>
                                <div className="view-btns justify-content-end">
                                  <span
                                    className="left-seats py-1 px-2 fs-7 cursor-pointer"
                                    onClick={() => {
                                      // (async () => {
                                      //   await localStorage.setItem(
                                      //     "event",
                                      //     JSON.stringify(item)
                                      //   );
                                      // })();
                                      // dispatch(setEventDetail(item));
                                      navigate(`/event/${item.id}`);
                                    }}
                                  >
                                    More Info
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                        </Carousel>
                        <div className="mt-2 cursor-pointer">
                          <span
                            className="mt-2 fw-bold theme-color cursor-pointer"
                            onClick={() => {
                              navigate("/events");
                            }}
                          >
                            View All Events
                          </span>
                        </div>
                      </>
                    ) : (
                      <div>No new events.</div>
                    )}
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12">
                  <hr />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                  <h3>Account Holder's Details</h3>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                  <div className="bg-white p-3 rounded expandible">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-2 mb-2">
                        <p className="detail-account text-capitalize position-relative">
                          <img
                            src="assets/img/First-Name.png"
                            className="icon-title"
                            alt=""
                          />
                          <span>full name</span>
                          <span>
                            <b>
                              {detail
                                ? `${detail?.first_name} ${detail?.last_name}`
                                : "-"}
                            </b>
                          </span>
                        </p>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-5 mb-2">
                        <p className="detail-account position-relative">
                          <img
                            src="assets/img/email.png"
                            className="icon-title"
                            alt=""
                          />
                          <span className="text-capitalize">email ID</span>
                          <span>
                            <b>{detail ? `${detail?.email}` : "-"}</b>
                          </span>
                        </p>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-3 mb-2">
                        <p className="detail-account position-relative">
                          <img
                            src="assets/img/First-Name.png"
                            className="icon-title"
                            alt=""
                          />
                          <span className="text-capitalize">username</span>
                          <span>
                            <b>{detail ? `${detail?.username}` : "-"}</b>
                          </span>
                        </p>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <hr />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-2 mb-2">
                        <p className="detail-account text-capitalize position-relative">
                          <img
                            src="assets/img/date-of-birth.png"
                            className="icon-title"
                            alt=""
                          />
                          <span>date of birth</span>
                          <span>
                            <b>
                              {detail?.address_details?.dob &&
                              detail?.address_details?.dob !== "0000-00-00"
                                ? `${moment(
                                    detail?.address_details?.dob
                                  ).format("DD MMM YYYY")}`
                                : detail?.address_details?.dob_year
                                ? `${moment(
                                    `${detail?.address_details?.dob_year}-${detail?.address_details?.dob_month}-${detail?.address_details?.dob_day}`
                                  ).format("DD MMM YYYY")}`
                                : "-"}
                            </b>
                          </span>
                        </p>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-5 mb-2">
                        <p className="detail-account text-capitalize position-relative">
                          <img
                            src="assets/img/address.png"
                            className="icon-title"
                            alt=""
                          />
                          <span className="">full address</span>
                          <span>
                            <b>
                              {detail
                                ? `${detail?.address_details?.property_number}-${detail?.address_details?.property_name}, ${detail?.address_details?.street_number}, ${detail?.address_details?.town}, ${detail?.address_details?.country} - ${detail?.address_details?.postal_code} `
                                : "-"}
                            </b>
                          </span>
                        </p>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-3 mb-2"></div>
                      {/* <div className="col-sm-12 col-md-6 col-lg-2 mb-2">
                        <div className="view-btns justify-content-end">
                          <button className="btn btn-accept btn-view w-auto">
                            Cancel Account
                          </button>
                        </div>
                      </div> */}
                      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                        <div
                          className="bg-white px-2 py-3  position-relative h-100"
                          style={{
                            textTransform: "uppercase",
                          }}
                        >
                          <h5 className=" mb-1">
                            {consents?.length > 1 ? "Consents" : "Consent"}
                          </h5>
                          {detail?.consent?.map((item, i) => {
                            return (
                              <React.Fragment key={i}>
                                <p>
                                  <span>{item?.consent_details?.label}: </span>
                                  <span className="fw-bold">{item?.value}</span>
                                </p>
                                {item?.consent_details?.id === 6 && (
                                  <p>
                                    <span>Medication: </span>
                                    <span className="fw-bold">
                                      {item?.note}
                                    </span>
                                  </p>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4 col-xl-3 mb-3">
              <div className=" py-4 px-3 record-listing h-100">
                <div className="view-btns justify-content-center mb-3">
                  <span
                    className="btn-accept btn-view w-auto cursor-pointer"
                    onClick={() => {
                      if (detail?.wallet < 0) {
                        navigate("/make-payment", {
                          state: {
                            amount: detail?.wallet,
                            payment_for: "wallet",
                          },
                        });
                      }
                    }}
                  >
                    Balance/Credit:{" "}
                    <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                    {detail?.wallet || 0}
                  </span>
                </div>
                <h4 className="text-center mb-3">Members Records</h4>

                <button
                  className="btn btn-create-profile shadow fw-bold w-100 text-center fs-5 p-2 mb-2"
                  onClick={() => {
                    console.log("Hello");
                    navigate("/add-student");
                  }}
                >
                  + Add New Student
                </button>

                {detail?.student_list?.length > 0 && (
                  <>
                    <h6 className="mt-2">Student Details</h6>

                    <div className="bg-white p-2 text-capitalize mt-3">
                      <div
                        className="accordion Student-Details-colles"
                        id="accordionExample"
                      >
                        {detail?.student_list?.map((item, i) => (
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                              <button
                                className="accordion-button"
                                type="button"
                                aria-expanded="true"
                                aria-controls={`collapseOne${item.id}`}
                                onClick={() => {
                                  toggle();
                                  setStudentId(item?.id);
                                }}
                              >
                                <p className="student_detail-account position-relative">
                                  <img
                                    src="assets/img/First-Name.png"
                                    className="icon-title"
                                    alt=""
                                  />
                                  <span>student name</span>
                                  <span>
                                    <b>
                                      {item?.first_name
                                        ? item?.first_name +
                                          " " +
                                          item?.last_name
                                        : ""}
                                    </b>
                                  </span>
                                </p>
                              </button>
                            </h2>
                            <div
                              className={
                                show && studentId === item.id
                                  ? "accordion-collapse collapse show"
                                  : "accordion-collapse collapse"
                              }
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <p className="student_detail-account position-relative">
                                  <img
                                    src="assets/img/address.png"
                                    className="icon-title"
                                    alt=""
                                  />
                                  <span className="">full address</span>
                                  <span>
                                    <b>
                                      {detail?.address_details
                                        ? `${
                                            detail?.address_details
                                              ?.property_number
                                          }${
                                            detail?.address_details
                                              ?.property_name
                                              ? `${detail?.address_details?.property_name}, `
                                              : ", "
                                          } ${
                                            detail?.address_details
                                              ?.street_number
                                          }, ${
                                            detail?.address_details?.town
                                          }, ${
                                            detail?.address_details?.country
                                          }`
                                        : "NA"}
                                    </b>
                                  </span>
                                </p>
                                <p className="student_detail-account position-relative">
                                  <img
                                    src="assets/img/membership-status.png"
                                    className="icon-title"
                                    alt=""
                                  />
                                  <span className="">membership status</span>
                                  <span>
                                    <b>
                                      {item?.trialAccountStatus === "1"
                                        ? "Trial"
                                        : item?.trialAccountStatus === "3"
                                        ? "Active"
                                        : item?.trialAccountStatus === "2"
                                        ? "Inactive"
                                        : ""}
                                    </b>
                                  </span>
                                </p>
                                <p className="student_detail-account position-relative">
                                  <img
                                    src="assets/img/membership-status.png"
                                    className="icon-title"
                                    alt=""
                                  />
                                  <span className="">membership level</span>
                                  <span>
                                    <b>{item?.student_type || "NA"}</b>
                                  </span>
                                </p>
                                <p className="student_detail-account position-relative">
                                  <img
                                    src="assets/img/date-of-birth.png"
                                    className="icon-title"
                                    alt=""
                                  />
                                  <span className="">date of birth</span>
                                  <span>
                                    <b>
                                      {item?.dob && item?.dob !== "0000-00-00"
                                        ? `${moment(item?.dob).format(
                                            "DD MMM YYYY"
                                          )}`
                                        : item?.dob_year
                                        ? `${moment(
                                            `${item?.dob_year}-${item?.dob_month}-${item?.dob_day}`
                                          ).format("DD MMM YYYY")}`
                                        : "-"}
                                    </b>
                                  </span>
                                </p>
                                <p className="student_detail-account position-relative">
                                  <img
                                    src="assets/img/discipline.png"
                                    className="icon-title"
                                    alt=""
                                  />
                                  <span className="">discipline</span>
                                  <span>
                                    <b>
                                      {item?.discipline_details?.name || "NA"}
                                    </b>
                                  </span>
                                </p>
                                <hr />
                                <p className="student_detail-account position-relative">
                                  <img
                                    src="assets/img/First-Name.png"
                                    className="icon-title"
                                    alt=""
                                  />
                                  <span className="">account holder</span>
                                  <span>
                                    <b>
                                      {detail?.first_name
                                        ? `${detail?.first_name} ${detail?.last_name}`
                                        : ""}
                                    </b>
                                  </span>
                                </p>
                                <p className="student_detail-account teacher-student position-relative">
                                  <img
                                    src="assets/img/mobile.png"
                                    className="icon-title"
                                    alt=""
                                  />
                                  <span className="">
                                    emergency contact number
                                  </span>
                                  <span>
                                    <b>
                                      {item?.emergency_contactNo &&
                                      item?.emergency_contactNo !== "null" &&
                                      item?.emergency_contactNo !== "undefined"
                                        ? item?.emergency_countryCode +
                                          item?.emergency_contactNo
                                        : "NA"}
                                    </b>
                                  </span>
                                </p>
                                {item?.medical_note && <hr />}
                                {item?.medical_note && (
                                  <p className="student_detail-account teacher-student position-relative">
                                    <img
                                      src="assets/img/medical.png"
                                      className="icon-title"
                                      alt=""
                                    />
                                    <span className="">medical note</span>
                                    <span>{item?.medical_note || "NA"}</span>
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Dashboard;
