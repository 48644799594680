import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router";

function Confirmation(props) {
  const navigate = useNavigate();
  return (
    <>
      <Modal show={props.show} onHide={props.handleShow} size="lg">
        <Modal.Body>
          <div className=" modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header d-grid text-center">
                <h5 className="mdl-ttl">Do you want to add another Class?</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    props.handleShow();
                  }}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                    <button
                      className="btn btn-accept btn-view mt-2"
                      onClick={() => {
                        navigate("/cart", { replace: true });
                      }}
                    >
                      No
                    </button>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
                    <button
                      className="btn btn-accept btn-view mt-2"
                      onClick={() => {
                        props.handleShow();
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Confirmation;
