import React from "react";

function StudentMembershipDetail() {
  return (
    <div className="bg-white p-2 text-capitalize Student-Details-colles">
      <div className="accordion-item p-2 mb-0">
        <p className="student_detail-account position-relative">
          <img src="assets/img/First-Name.png" className="icon-title" alt="" />
          <span>student name</span>
          <span>
            <b>thomas kors</b>
          </span>
        </p>
        <p className="student_detail-account position-relative">
          <img src="assets/img/address.png" className="icon-title" alt="" />
          <span className="">full address</span>
          <span>
            <b>3017, airplane avenue, arimo idaho- 83214</b>
          </span>
        </p>
        <p className="student_detail-account position-relative">
          <img
            src="assets/img/membership-status.png"
            className="icon-title"
            alt=""
          />
          <span className="">membership status</span>
          <span>
            <b>active</b>
          </span>
        </p>
        <p className="student_detail-account position-relative">
          <img
            src="assets/img/membership-status.png"
            className="icon-title"
            alt=""
          />
          <span className="">membership lavel</span>
          <span>
            <b>child</b>
          </span>
        </p>
        <p className="student_detail-account position-relative">
          <img
            src="assets/img/date-of-birth.png"
            className="icon-title"
            alt=""
          />
          <span className="">date of birth</span>
          <span>
            <b>13 aug 1995</b>
          </span>
        </p>
        <p className="student_detail-account position-relative">
          <img src="assets/img/discipline.png" className="icon-title" alt="" />
          <span className="">discipline</span>
          <span>
            <b>karate</b>
          </span>
        </p>
        <button className="btn btn-view btn-reject  fs-5 mb-1 w-100">
          Cancel Membership
        </button>
        <button className="btn btn-view btn-accept  fs-5 mb-1 w-100">
          Cancel Account
        </button>
      </div>
    </div>
  );
}

export default StudentMembershipDetail;
