import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";

function EditRequest({ show, handleShow, confirm, type }) {
  const [request_for_edit_consent, setRequest_for_edit_consent] =
    useState(false);

  return (
    <Modal show={show} onHide={handleShow} size="md">
      <div className="modal-dialog modal-dialog-centered ">
        <div className="modal-content body-bg">
          <div className="modal-header border-0">
            <button
              type="button"
              className="btn-close"
              onClick={handleShow}
            ></button>
          </div>
          <div className="modal-body text-center mb-4">
            <h3>
              {type === "editProfile" ? "Profile" : "Consent"} Edit Request
            </h3>
            {type === "editProfile" ? (
              <p>Do you want to send edit request for your profile??</p>
            ) : null}
            <div>
              <input
                id="request_for_edit_consent"
                type="checkbox"
                checked={request_for_edit_consent}
                onChange={() => {
                  setRequest_for_edit_consent(!request_for_edit_consent);
                }}
                className="m-1"
              />
              <label htmlFor="request_for_edit_consent">
                Send request to edit consent
              </label>
            </div>
            <div className="view-btns mt-3">
              <button
                className="btn btn-reject btn-view mt-2"
                onClick={() => {
                  handleShow();
                }}
              >
                No
              </button>
              <button
                className="btn btn-accept btn-view mt-2"
                onClick={() => {
                  handleShow();
                  confirm(request_for_edit_consent);
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default EditRequest;
