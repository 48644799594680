import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import OtpInput from "react-otp-input";
import { format } from "../../utils";
import { otpSchema } from "../../utils/schema";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchUserDetail,
  ResendOtpCall,
  userDetailSelector,
  verifyOtpCall,
} from "../../features/slice";
import { toast } from "react-toastify";
import Loader from "../Loader";
function OtpModal({ show, handleShow, id }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [counter, setCounter] = React.useState(120);
  const [loading, setLoading] = useState(false);
  const user = useSelector(userDetailSelector);
  const email = localStorage.getItem("email");
  useEffect(() => {
    dispatch(FetchUserDetail({}));
  }, [dispatch]);
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    resolver: yupResolver(otpSchema),
    shouldFocusError: true,
  });

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(
      verifyOtpCall({
        userId: id ? id : user.id,
        code: data?.otpValues,
        user_type: "user",
      })
    ).then(async (res) => {
      setLoading(false);
      if (res?.payload?.code === 1) {
        await localStorage.setItem("token", res?.payload?.data?.token);
        handleShow();
        navigate("/address", { replace: true });
        toast.success(res?.payload?.message);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  React.useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter((c) => c - 1), 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [counter]);

  const handleResend = () => {
    setLoading(true);
    const payload = {
      email: user?.email ? user?.email : email ? email : "",
      user_type: "user",
    };
    dispatch(ResendOtpCall(payload)).then(async (res) => {
      setLoading(false);
      setCounter(60);
      if (res?.payload?.code === 1) {
        toast.success(res?.payload?.message);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };
  return (
    <Modal
      show={show}
      onHide={() => {
        if (user?.profile_stag === "0") {
          handleShow();
          navigate("/");
        } else {
          handleShow();
        }
      }}
      size="lg"
    >
      <Modal.Body>
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content other-city b-r-20">
            <div className="modal-header text-center">
              <h2 className="mdl-ttl">OTP Verifications</h2>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  if (user?.profile_stag === "0") {
                    handleShow();
                    navigate("/");
                  } else {
                    handleShow();
                  }
                }}
              ></button>
            </div>
            <div className="modal-body">
              <form
                className="row form-style profile-form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form-group col-sm-12 col-md-12 text-center mb-2">
                  <div className="text-center mb-5">
                    <img
                      src="assets/img/otp.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <p className="mdl-sbttl">
                    Please enter 6 digit code which has been send on your
                    <br />
                    registered email address.
                  </p>
                </div>
                <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                  {/* <a href="#" className="forgot-pass theme-color">
                    Click the link
                  </a> */}
                </div>
                <div className="form-group col-sm-12 col-md-12 mb-4">
                  <div className="passcode-wrapper">
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <OtpInput
                          value={value}
                          onChange={(val) => {
                            if (val) {
                              onChange(val);
                            }
                          }}
                          placeholder="000000"
                          inputStyle="otpInputs"
                          numInputs={6}
                          separator={<span> </span>}
                        />
                      )}
                      name="otpValues"
                      control={control}
                    />
                  </div>
                  {errors?.otpValues && (
                    <div className="text-red">{errors?.otpValues?.message}</div>
                  )}
                </div>
                <div className="form-group col-sm-12 col-md-12 text-center">
                  <div>
                    {counter === 0 ? (
                      <span
                        className="forgot-pass cursor-pointer p-0 theme-color"
                        onClick={() => {
                          handleResend();
                        }}
                      >
                        Resend Code
                      </span>
                    ) : (
                      <div>Resend code in {format(counter)}</div>
                    )}
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 text-center">
                  <button type="submit" className="btn btn-submit">
                    {loading ? <Loader /> : "Verify"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default OtpModal;
