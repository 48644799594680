import {
  getData,
  getDataAdmin,
  getDataforUrl,
  patchFormData,
  postFormData,
  postRawData,
  putFormData,
} from "./index";

export const Signup = async (payload) => {
  return postFormData("auth/signup", payload).then((data) => {
    return data;
  });
};

// userId, code

export const VerifyOtp = async (payload) => {
  return postFormData("auth/verifyOtp", payload).then((data) => {
    return data;
  });
};
// property_number, property_name, street_number, town, country, postal_code, mobile, countryCode, dob (yyyy-mm-dd)
export const AddAddress = async (payload) => {
  return postFormData("auth/addAddress", payload).then((data) => {
    return data;
  });
};
export const addStudent = async (payload) => {
  return postFormData("student/addStudent", payload).then((data) => {
    return data;
  });
};

export const getAllSchoolVenues = async (payload) => {
  return getData("admin/schoolList", payload).then((data) => {
    return data;
  });
};
export const getSchoolDetail = async (payload) => {
  return getDataforUrl("admin/getSchoolDetails", payload).then((data) => {
    return data;
  });
};
export const Login = async (payload) => {
  return postFormData("auth/login", payload).then((data) => {
    return data;
  });
};
export const ResendOtp = async (payload) => {
  return getData("auth/resendOtp", payload).then((data) => {
    return data;
  });
};
export const forgotPassword = async (payload) => {
  return getData("auth/forgotPassword", payload).then((data) => {
    return data;
  });
};

export const resetPassword = async (payload) => {
  return putFormData("auth/resetPassword", payload).then((data) => {
    return data;
  });
};
export const changePassword = async (payload) => {
  return putFormData("auth/changePassword", payload).then((data) => {
    return data;
  });
};

export const addMemberShipLevel = async (payload) => {
  return postFormData("auth/addMemberShipLevel", payload).then((data) => {
    return data;
  });
};

export const addConsent = async (payload) => {
  return postRawData("user/addConsent", payload).then((data) => {
    return data;
  });
};

export const requestToDeleteAccount = async (payload) => {
  return postFormData("user/requestToDeleteAccount", payload).then((data) => {
    return data;
  });
};

export const consentList = async (payload) => {
  return getData("user/consentList", payload).then((data) => {
    return data;
  });
};
export const subscribeNewsletter = async (payload) => {
  return postFormData("user/subscribeNewsletter", payload).then((data) => {
    return data;
  });
};
export const updateDeviceToken = async (payload) => {
  return patchFormData("user/updateDeviceToken", payload).then((data) => {
    return data;
  });
};
