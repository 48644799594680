import * as Yup from "yup";

export const createAccountSchema = Yup.object({
  image: Yup.string().optional().nullable().notRequired(),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email("Kindly enter a valid email.").required("Required"),
  emailConfirmation: Yup.string()
    .oneOf([Yup.ref("email"), null], "Email ID must match")
    .required("Kindly confrim your Email ID."),
  userName: Yup.string()
    .matches(/^(\S+$)/g, "Username can not have spaces.")
    .required("Required"),
  password: Yup.string()
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#£\.€\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    .required("Required"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Kindly confirm your password."),
  tnc: Yup.boolean()
    .oneOf([true], "Kindly accept the terms and conditions.")
    .required("Kindly accept the terms and conditions."),
  newsletter: Yup.boolean(),
});

export const loginSchema = Yup.object({
  email: Yup.string().required("Required"),
  password: Yup.string()
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#£\.€\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    .required("Required"),
});

export const forgetPasswordSchema = Yup.object({
  email: Yup.string().email("Kindly enter a valid email.").required("Required"),
});

export const otpSchema = Yup.object({
  otpValues: Yup.string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(6, "Kindly enter valid otp.")
    .max(6, "Kindly enter valid otp."),
});

export const resetPasswordSchema = Yup.object({
  password: Yup.string()
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#£\.€\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    .required("Required"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Password doesn't match."
  ),
});

export const addAddressSchema = Yup.object({
  propertyNumber: Yup.string().typeError("Required").required("Required"),
  propertyName: Yup.string().typeError("Required").notRequired(),
  streetName: Yup.string().typeError("Required").required("Required"),
  town: Yup.string().typeError("Required").required("Required"),
  country: Yup.string().typeError("Required").required("Required"),
  postalCode: Yup.string().typeError("Required").required("Required"),
  mobile: Yup.string()
    .typeError("Kindly enter a valid phone number.")
    .required("Required"),
  // countryCode: Yup.number()
  //   .positive()
  //   .integer()
  //   .typeError("Required")
  //   .required("Required"),
  // dob: Yup.string().typeError("Required").required("Required"),
  day: Yup.string().typeError("Required").required("Required"),
  month: Yup.string().typeError("Required").required("Required"),
  year: Yup.string().typeError("Required").required("Required"),
});

export const addMultipleStudentSchema = Yup.object({
  student_data: Yup.array()
    .of(
      Yup.object({
        studentFirstName: Yup.string().required("Required"),
        studentLastName: Yup.string().required("Required"),
        day: Yup.string().typeError("Required").required("Required"),
        month: Yup.string().typeError("Required").required("Required"),
        year: Yup.string().typeError("Required").required("Required"),
        student_type: Yup.string()
          .typeError("Required")
          .required("Student must be at least 4 years."),

        membership_level: Yup.string().when("trial_membership", {
          is: (value) => value === "" || value === undefined,
          then: Yup.string().typeError("Required").required("Required"),
        }),

        discipline: Yup.string().typeError("Required").required("Required"),
        mobile: Yup.string()
          .typeError("Kindly enter a valid phone number.")
          .notRequired()
          .nullable()
          .optional(),

        note: Yup.string().typeError("Required").required("Required"),
        areacode: Yup.string().typeError("Required").required("Required"),
        schoolId: Yup.string().typeError("Required").required("Required"),
        image: Yup.string().optional().nullable().notRequired(),
        trial_membership: Yup.string(),
      })
    )
    .min(1, "Kindly add a student"),
});

export const addStudentDetailSchema = Yup.object({
  studentFirstName: Yup.string().required("Required"),
  studentLastName: Yup.string().required("Required"),
  // dob: Yup.string().typeError("Required").required("Required"),
  day: Yup.string().typeError("Required").required("Required"),
  month: Yup.string().typeError("Required").required("Required"),
  year: Yup.string().typeError("Required").required("Required"),
  // student_type: Yup.string().typeError("Required"),

  membership_level: Yup.string().when("trial_membership", {
    is: (value) => value === "" || value === undefined || value === "3",
    then: Yup.string().typeError("Required").required("Required"),
  }),

  discipline: Yup.string().typeError("Required").required("Required"),
  mobile: Yup.string()
    .typeError("Kindly enter a valid phone number.")
    .notRequired()
    .nullable()
    .optional(),

  note: Yup.string(),
  areacode: Yup.string().typeError("Required").required("Required"),
  schoolId: Yup.string().typeError("Required").required("Required"),
  image: Yup.string().optional().nullable().notRequired(),
  trial_membership: Yup.string(),
  tnc: Yup.boolean()
    .oneOf([true], "Kindly accept the terms and conditions.")
    .required("Kindly accept the terms and conditions."),
  useWalletAmount: Yup.boolean(),
});

export const membershipLevelSchema = Yup.object({
  isTrailMembership: Yup.boolean(),
  size: Yup.string().when("isTrailMembership", {
    is: false,
    then: Yup.string().typeError("Required").required("Kindly select a size."),
  }),
});

export const consentSchema = Yup.object({
  user_consents: Yup.array().min(1, "Required"),
});

export const editUserProfileSchema = Yup.object({
  image: Yup.string().optional().nullable().notRequired(),
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  username: Yup.string().required("Required"),
  email: Yup.string().email("Kindly enter a valid email.").required("Required"),
  property_number: Yup.string().typeError("Required").required("Required"),
  property_name: Yup.string().nullable().notRequired().optional(),
  street_number: Yup.string().typeError("Required").required("Required"),
  town: Yup.string().typeError("Required").required("Required"),
  country: Yup.string().typeError("Required").required("Required"),
  postal_code: Yup.string().typeError("Required").required("Required"),
  mobile: Yup.string().required("Required"),

  dob_year: Yup.string().required("Required"),
  dob_month: Yup.string().required("Required"),
  dob_day: Yup.string().required("Required"),
  user_consents: Yup.array().min(1, "Kindly check all the points."),
});
