import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import { addStudentDetailSchema } from "../../utils/schema";

import { useDispatch } from "react-redux";
import {
  addMemberShip,
  addStudentCall,
  areaCodeListSelector,
  FetchAreaCodeList,
  FetchDisciplineList,
  FetchTNC,
  FetchUserDetail,
  getSchools,
  tncSelector,
  userDetailSelector,
} from "../slice";
import { toast } from "react-toastify";
import { uploadFile } from "react-s3";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { config, htmlStringToPdf, monthOption } from "../../utils/index.js";
import { useSelector } from "react-redux";
import { FetchMembershipListing } from "../membership/MembershipSlice";
import { addTrailMemberShip } from "../../api/student";
import {
  isValidPhoneNumber,
  formatPhoneNumberIntl,
} from "react-phone-number-input";
import Phone from "../../component/phoneInput/Phone";
import BuyMembershipPayment from "../../component/Modal/BuyMembershipPayment";
import BuyMembershipModal from "../../component/Modal/BuyMembershipModal";
import moment from "moment";
import WantToAddAnotherStudent from "../../component/Modal/WantToAddAnotherStudent";
import { getDataforUrl, postFormData } from "../../api";
import { addStudentDataReducer } from "../student/StudentSlice";
import { useSearchParams } from "react-router-dom";
import { sendMembershipSticker } from "../../api/membership";
import { v4 as uuidv4 } from "uuid";
import S3 from "aws-s3";
import { getSchoolDetail } from "../../api/authentications.js";
const S3Client = new S3(config);

window.Buffer = window.Buffer || require("buffer").Buffer;
function AddStudentDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const payload = useSelector((state) => state.student.bookingMembershipData);
  let transactionId = searchParams?.get("transactionId");
  const { id } = useParams();
  const areaCodes = useSelector(areaCodeListSelector);
  const user = useSelector(userDetailSelector);
  const [schoolList, setSchoolList] = useState([]);
  const [time, setTime] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const membershipLists = useSelector((state) => state.membership.list);
  const [showBuyMembership, setShowBuyMembership] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const disciplines = useSelector((state) => state.main.disciplinesList);
  const [showAnother, setShowAnother] = useState(false);
  const [yearList, setYearList] = useState([]);
  // const payload = useSelector((state) => state.student.bookingMembershipData);
  const getDisciplines = useCallback(() => {
    dispatch(FetchDisciplineList({ page: 1, limit: 1000000 }));
  }, [dispatch]);

  useEffect(() => {
    getDisciplines();
  }, [getDisciplines]);

  useEffect(() => {
    if (transactionId && payload?.userMembershipId) {
      getDataforUrl("booking/getPaymentDetails", transactionId)
        .then((ret) => {
          // console.log(ret, "Payment detail");
          return ret;
        })
        .then((transaction) => {
          // console.log(transaction, "Transaction");
          if (
            transaction?.code === 1 &&
            transaction?.data?.payment?.status?.toLowerCase() === "completed"
          ) {
            setShowBuyMembership(false);
            setShowAnother(true);
            dispatch(FetchUserDetail({}));
            const studentData = payload?.studentData;
            const userData = payload?.userData;
            const sendHtmlAsString = async (nameD = "") => {
              let htmlPart = `<!DOCTYPE html>
  <html lang="en">

  <head>
      <meta charset="utf-8">
      <meta http-equiv="Content-Type" content="text/html;charset=UTF-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">

      <title>Membership Details</title>

  </head>

  <body style="width: 100 mm;height: 56 mm;">
      <div class="mw-body"
          style="width: 100%;height: 100%;
      font-family: Roboto Slab,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji">
          <div>
              <table style="width: 100%;height: 100%;border-collapse: separate;
              border-spacing: 0 0em;text-transform: uppercase; font-size: 12px; font-weight: 700;margin: 1mm;padding: 5px;">
                  <tr>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
      border-right: 0;"><span>name</span></td>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;"><span style="color: #ff0000;"> ${
                        studentData?.first_name
                      } ${studentData?.last_name}</span></td>

                  </tr>
                  <tr>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          border-right: 0;"><span>address</span></td>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;"><span
                              style="color: #ff0000;"> ${
                                userData?.property_name
                              }, ${
                userData?.property_number
                  ? `${userData?.property_number}, `
                  : ""
              } ${userData?.street_number}</span></td>

                  </tr>
                  <tr>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          border-right: 0;"><span>town</span></td>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          "><span style="color: #ff0000;"> ${
                            userData?.town
                          }</span></td>
                  </tr>
                  <tr>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          border-right: 0;"><span>postcode</span></td>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          "><span style="color: #ff0000;"> ${
                            userData?.postal_code
                          }</span></td>
                  </tr>
                  <tr>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          border-right: 0;"><span>dob</span></td>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          "><span style="color: #ff0000;"> ${moment(
                            studentData?.dob
                          ).format("DD MMM YYYY")}</span></td>
                  </tr>
                  <tr>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          border-right: 0;"><span>school</span></td>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          "><span style="color: #ff0000;"> ${
                            studentData?.school_details?.name
                          }</span></td>
                  </tr>
                    <tr>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          border-right: 0;"><span>discipline</span></td>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          "><span style="color: #ff0000;"> ${
                            studentData?.discipline_details?.name
                          }</span></td>
                  </tr>
                  <tr>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          border-right: 0;"><span>first licence</span></td>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          "><span style="color: #ff0000;"> ${moment(
                            studentData?.membership_details?.start_date
                          ).format("DD MMM YYYY")}</span></td>
                  </tr>
                  <tr>
                      <td style="border-collapse: collapse;padding: 1mm;
                          border-right: 0;"><span>date</span></td>
                      <td style="border-collapse: collapse;padding: 1mm;
                          "><span style="color: #ff0000;"> ${moment(
                            studentData?.membership_details?.valid_till
                          ).format("DD MMM YYYY")}</span></td>
                  </tr>
              </table>
          </div>
  </body>

  </html>`;

              const pdfOutput = await htmlStringToPdf(htmlPart);

              return pdfOutput;
            };

            sendHtmlAsString().then((ob) => {
              if (ob) {
                console.log(ob);
                const newFileName = uuidv4();
                S3Client.uploadFile(ob, newFileName)
                  .then((data) => {
                    console.log(data, "S3 data");

                    if (data?.location) {
                      const stickerPayload = {
                        user_membership_id: payload?.userMembershipId,
                        file_url: data?.location,
                        userId: studentData?.userId,
                      };
                      sendMembershipSticker(stickerPayload).then((res) => {
                        if (res?.code === 1) {
                          // setShowBuyMembership(false);
                          navigate("/student", { replace: true });
                          toast.success(res?.message);
                        } else {
                          toast.error(res?.message);
                        }
                      });
                    }
                  })

                  .catch((err) => console.error(err));
              }
            });
            (async () => {
              await localStorage.removeItem("bookingMembershipData");
            })();
            // postFormData("booking/savePaymentDetails", {
            //   userMembershipId: payload?.userMembershipId,
            //   amount: transaction?.data?.payment?.amountMoney?.amount / 100,
            //   order_id: transaction?.data?.payment?.orderId,
            //   transaction_id: transaction?.data?.payment?.id,
            //   payment_for: "membership",
            //   payment_status: transaction?.data?.payment?.status,
            // }).then((resp) => {
            //   if (resp?.code === 1) {

            //     toast.success(resp?.message);
            //   } else {
            //     toast.error(resp?.message);
            //   }
            // });
          }
        });
    }
  }, [transactionId, payload?.data]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    watch,
    setError,
    reset,
    setValue,
    clearErrors,
    control,
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    resolver: yupResolver(addStudentDetailSchema),
    shouldFocusError: true,
  });

  const watchAreaCode = watch("areacode");
  const watchTrailMembership = watch("trial_membership");
  const watchDay = watch("day");
  const watchMonth = watch("month");
  const watchYear = watch("year");
  const watchMobile = watch("mobile");
  const watchDiscipline = watch("discipline");
  const watchStudentType = watch("student_type");
  const watchMembershipLevel = watch("membership_level");

  useEffect(() => {
    dispatch(FetchUserDetail());
  }, [dispatch]);

  const isValid = (val) => {
    // if (watchMobile && !isValidPhoneNumber(watchMobile)) {
    //   setError("mobile", { message: "Invalid Number" });
    //   return false;
    // } else {
    //   setError("mobile", { message: "" });
    // }
    return true;
  };
  const onSubmit = (dataa) => {
    console.log(dataa);
    const valid = dataa?.mobile ? isValid(dataa?.mobile) : true;
    // console.log(valid, "Is valid");
    const date1 = new Date();
    const date2 = new Date(`${dataa?.month}/${dataa?.day}/${dataa?.year}`);
    let diff = (date1.getTime() - date2.getTime()) / 1000;
    diff /= 60 * 60 * 24;
    // console.log(diff);
    diff = Math.abs(Math.round(diff / 365.25));
    // console.log(diff, "ugeyfcdvh");
    const st_type =
      diff >= 4 && diff <= 16 ? "child" : diff > 16 ? "adult" : "";

    const payload = {
      ...dataa,
      student_type: st_type,
    };
    console.log(dataa, "dataa");

    var today = new Date();
    var birthDate = new Date(`${dataa?.month}/${dataa?.day}/${dataa?.year}`);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    console.log(age, "Ageshgfchjk");

    if (
      (dataa?.discipline === "1" && age >= 4) ||
      (dataa?.discipline === "2" && age >= 7)
    ) {
      if (valid) {
        if (watchTrailMembership === "1") {
          console.log("trail kjksgvc ");
          handleAddStudent(payload);
          // .then((response) => {
          //   if (response?.payload?.code === 1) {
          //     addTrailMemberShip({
          //       studentId: response?.payload?.data?.student?.id,
          //     }).then((res) => {
          //       console.log(res, "Trial res ");
          //       if (res?.code === 1) {
          //         dispatch(FetchUserDetail({}));

          //         setShowAnother(true);
          //         toast.success(res?.payload?.message);
          //       }
          //     });
          //   }
          // });
        } else if (watchTrailMembership === "3") {
          setShowBuyMembership(true);
          // console.log("there");
        }
      } else {
        toast.error("Kindly enter a valid mobile number.");
      }
    } else {
      toast.error(
        dataa?.discipline === "1"
          ? "Age should be more than 4 years"
          : dataa?.discipline === "2"
          ? "Age should be more than 7 years"
          : null
      );
    }
  };

  const handleAddStudent = (data) => {
    console.log(data, "Add in add student");

    var today = new Date();
    var birthDate = new Date(`${data?.month}/${data?.day}/${data?.year}`);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    const countryCode = data?.mobile
      ? formatPhoneNumberIntl(data?.mobile).split(" ")[0]
      : "";

    if (data?.schoolId) {
      getSchoolDetail(data?.schoolId).then((res) => {
        // console.log(res, "res schollll lmncdb ");
        const sendHtmlAsString = async (nameD = "") => {
          let htmlPart = `<!DOCTYPE html>
      <html lang="en">
      
      <head>
      <meta charset="utf-8">
      <meta http-equiv="Content-Type" content="text/html;charset=UTF-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      
      <title>Membership Details</title>
      
      </head>
      
      <body style="width: 100 mm;height: 56 mm;">
      <div class="mw-body"
        style="width: 100%;height: 100%;
      font-family: Roboto Slab,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji">
        <div>
            <table style="width: 100%;height: 100%;border-collapse: separate;
            border-spacing: 0 0em;text-transform: uppercase; font-size: 12px; font-weight: 700;margin: 1mm;padding: 5px;">
                <tr>
                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
      border-right: 0;"><span>name</span></td>
                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;"><span style="color: #ff0000;"> ${
                      data?.studentFirstName
                    } ${data?.studentLastName}</span></td>
      
                </tr>
                <tr>
                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                        border-right: 0;"><span>address</span></td>
                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;"><span
                            style="color: #ff0000;"> ${
                              user?.address_details?.property_name
                            }, ${
            user?.address_details?.property_number
              ? `${user?.address_details?.property_number}, `
              : ""
          } ${user?.address_details?.street_number}</span></td>
      
                </tr>
                <tr>
                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                        border-right: 0;"><span>town</span></td>
                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                        "><span style="color: #ff0000;"> ${
                          user?.address_details?.town
                        }</span></td>
                </tr>
                <tr>
                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                        border-right: 0;"><span>postcode</span></td>
                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                        "><span style="color: #ff0000;"> ${
                          user?.address_details?.postal_code
                        }</span></td>
                </tr>
                <tr>
                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                        border-right: 0;"><span>dob</span></td>
                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                        "><span style="color: #ff0000;"> ${moment(
                          `${data?.year}-${data?.month}-${data?.day}`
                        ).format("DD MMM YYYY")}</span></td>
                </tr>
                <tr>
                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                        border-right: 0;"><span>school</span></td>
                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                        "><span style="color: #ff0000;"> ${
                          res?.data?.school_details?.name
                        }</span></td>
                </tr>
                 <tr>
                                <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                                    border-right: 0;"><span>discipline</span></td>
                                <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                                    "><span style="color: #ff0000;"> ${
                                      data?.discipline === 2
                                        ? "Kickboxing"
                                        : "Karate"
                                    }</span></td>
                            </tr>
                <tr>
                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                        border-right: 0;"><span>first licence</span></td>
                    <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                        "><span style="color: #ff0000;"> ${moment(
                          new Date()
                        ).format("DD MMM YYYY")}</span></td>
                </tr>
                <tr>
                    <td style="border-collapse: collapse;padding: 1mm;
                        border-right: 0;"><span>date</span></td>
                    <td style="border-collapse: collapse;padding: 1mm;
                        "><span style="color: #ff0000;"> ${moment(
                          new Date()
                        ).format("DD MMM YYYY")}</span></td>
                </tr>
            </table>
        </div>
      </body>
      
      </html>`;

          const pdfOutput = await htmlStringToPdf(htmlPart);

          return pdfOutput;
        };
        sendHtmlAsString().then((ob) => {
          if (ob) {
            const newFileName = uuidv4();
            S3Client.uploadFile(ob, newFileName)
              .then((dataSk) => {
                console.log(dataSk?.location, "dataSk?.location");
                if (dataSk?.location) {
                  const addPAy = {
                    first_name: data?.studentFirstName,
                    last_name: data?.studentLastName,
                    dob: `${data?.year}-${
                      data?.month < 10 ? `0${data?.month}` : data?.month
                    }-${data?.day < 10 ? `0${data?.month}` : data?.month}`,
                    dob_year: data?.year,
                    dob_month: data?.month,
                    dob_day: data?.day,
                    student_type: data?.student_type,
                    discipline: data?.discipline,
                    emergency_contactNo: data?.mobile
                      ? data?.mobile.replace(countryCode, "")
                      : "",
                    emergency_countryCode: data?.mobile ? countryCode : "",
                    medical_note: data?.note,
                    image: data.image || "",
                    studentId: id ? id : "",
                    areacode: data?.areacode,
                    schoolId: data?.schoolId,
                    trial_membership: data?.membership_level
                      ? ""
                      : data?.trial_membership
                      ? 1
                      : "",
                    membership_level: data?.membership_level || "",
                    gender: data?.gender || "",
                    // no_of_days: data?.membership_level ? data?.no_of_days : "",
                    // price: data?.membership_level ? data?.price : "",
                    wallet_used_amount: data?.wallet_used_amount || "",
                    transaction_amount: data?.transaction_amount || "",
                    payment_status:
                      data?.payment_status === "0" ? data?.payment_status : "1",
                    currency: "GBP",
                    merchant_support_email: data?.merchant_support_email,
                    redirect_on: data?.redirect_on ? data?.redirect_on : "",
                    sticker_url: dataSk?.location,
                  };
                  console.log(addPAy, "addPAy");

                  return dispatch(addStudentCall(addPAy)).then((response) => {
                    if (response?.payload?.code === 1) {
                      console.log("handleAddStudent Response", response);
                      setShowBuyMembership(false);
                      const studentData = response?.payload?.data?.student;
                      const userData = response?.payload?.data?.user_address;
                      if (response?.payload?.data?.payment_link) {
                        (async () => {
                          await localStorage.setItem(
                            "bookingMembershipData",
                            JSON.stringify({
                              payment_for: "membership",
                              sub_payment_for: "",
                              userMembershipId:
                                response?.payload?.data?.userMembershipId,
                              studentData,
                              userData,
                            })
                          );
                          dispatch(
                            addStudentDataReducer({
                              payment_for: "membership",
                              sub_payment_for: "",
                              userMembershipId:
                                response?.payload?.data?.userMembershipId,
                              studentData,
                              userData,
                            })
                          );
                        })();
                        let URI =
                          response?.payload?.data?.payment_link?.paymentLink
                            ?.url;
                        window.open(URI, "_self");
                      } else {
                        const stickerPayload = {
                          user_membership_id:
                            studentData?.membership_details?.id,
                          file_url: dataSk?.location,
                          userId: studentData?.userId,
                        };
                        sendMembershipSticker(stickerPayload).then((res) => {
                          if (res?.code === 1) {
                            addTrailMemberShip({
                              studentId: response?.payload?.data?.student?.id,
                            }).then((res) => {
                              console.log(res, "Trial res ");
                              if (res?.code === 1) {
                                dispatch(FetchUserDetail({}));
                                setShowAnother(true);
                                // navigate("/student", { replace: true });
                                // toast.success(res?.payload?.message);
                              }
                            });
                            setShowBuyMembership(false);
                            toast.success(res?.message);
                          } else {
                            toast.error(res?.message);
                          }
                        });
                      }
                      toast.success(response?.payload?.message);
                    } else {
                      toast.error(response?.payload?.message);
                    }
                    return response;
                  });
                }
              })

              .catch((err) => console.error(err));
          }
        });
      });
    }
  };

  const handleBuy = async (memberLevel) => {
    let datas = getValues();

    const date1 = new Date();
    const date2 = new Date(`${datas?.month}/${datas?.day}/${datas?.year}`);
    let diff = (date1.getTime() - date2.getTime()) / 1000;
    diff /= 60 * 60 * 24;
    console.log(diff);
    diff = Math.abs(Math.round(diff / 365.25));

    const st_type = diff >= 4 && diff <= 16 ? "child" : "adult";

    var today = new Date();
    var birthDate = new Date(`${datas?.month}/${datas?.day}/${datas?.year}`);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    console.log(age, "Age paymemt ");

    const payload = {
      data: {
        ...datas,
        membership_level: memberLevel?.id,
        student_type: st_type,
        wallet_used_amount: 0,
        transaction_amount:
          st_type === "child"
            ? memberLevel?.child_price
            : st_type === "adult"
            ? memberLevel?.adult_price
            : 0,
        payment_status: "0",
        currency: "GBP",
        merchant_support_email: user?.email,
        redirect_on: "add-student-details",
      },
      payment_for: "membership",
      api: "student/addStudent",
    };

    console.log(payload);
    if (payload?.data?.student_type) {
      console.log(payload?.data, "payload?.data");
      if (
        (payload?.data?.discipline === 1 && age >= 4) ||
        (payload?.data?.discipline === 2 && age >= 7)
      ) {
        handleAddStudent(payload?.data);
      } else {
        toast.error(
          payload?.data?.discipline === 1
            ? "Age should be more than 4 years"
            : payload?.data?.discipline === 2
            ? "Age should be more than 7 years"
            : null
        );
      }
      // .then((response) => {
      //   if (response?.payload?.code === 1) {
      //     console.log("handleAddStudent Response", response);
      //     setShowBuyMembership(false);
      //     const studentData = response?.payload?.data?.student;
      //     const userData = response?.payload?.data?.user_address;
      //     if (response?.payload?.data?.payment_link) {
      //       (async () => {
      //         await localStorage.setItem(
      //           "bookingMembershipData",
      //           JSON.stringify({
      //             payment_for: "membership",
      //             sub_payment_for: "",
      //             userMembershipId: response?.payload?.data?.userMembershipId,
      //             studentData,
      //             userData,
      //           })
      //         );
      //         dispatch(
      //           addStudentDataReducer({
      //             payment_for: "membership",
      //             sub_payment_for: "",
      //             userMembershipId: response?.payload?.data?.userMembershipId,
      //             studentData,
      //             userData,
      //           })
      //         );
      //       })();
      //       let URI = response?.payload?.data?.payment_link?.paymentLink?.url;
      //       window.open(URI, "_self");
      //     }
      //     toast.success(response?.payload?.message);
      //   } else {
      //     toast.error(response?.payload?.message);
      //   }
      // });
    } else {
      toast.error(
        "Student type is not defined. Kindly enter date of birth again."
      );
    }
  };

  useEffect(() => {
    dispatch(getSchools({ areacode: watchAreaCode })).then((res) => {
      if (res?.payload?.code === 1) {
        setSchoolList(res?.payload?.data?.school_list);
      }
    });
  }, [watchAreaCode, dispatch]);

  useEffect(() => {
    dispatch(FetchAreaCodeList());
  }, [dispatch]);

  useEffect(() => {
    let interval;
    if (isActive && time < 100) {
      interval = setInterval(() => {
        // if (time < 100)
        setTime((seconds) => (seconds < 99 ? seconds + 1 : seconds));
      }, 10);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  const handleImage = (e) => {
    const { files } = e.target;
    setIsActive(true);

    uploadFile(files[0], config)
      .then((data) => {
        setValue("image", data?.location);
        setTime(100);
        setIsActive(true);
        setTimeout(() => {
          setTime(0);
          setIsActive(false);
        }, 2000);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (id) {
      //  reset({
      //    studentFirstName: data?.first_name,
      //    studentLastName: data?.last_name,
      //    // dob: new Date(data?.dob),
      //    year: new Date(data?.dob)?.getFullYear().toString(),
      //    month: new Date(data?.dob)?.getMonth() + 1,
      //    day: moment(data?.dob).format("DD"),
      //    student_type: data?.student_type,
      //    discipline: data?.discipline,
      //    mobile: data?.emergency_contactNo ? data?.emergency_contactNo : "",
      //    countryCode: data?.emergency_contactNo
      //      ? data?.emergency_countryCode
      //      : "",
      //    note: data?.medical_note,
      //    areacode: data?.areacode,
      //    schoolId: data?.schoolId,
      //    image: data?.image?.includes("http") ? data?.image : "",
      //    trial_membership:
      //      data?.trialAccountStatus === null || data?.trialAccountStatus === null
      //        ? true
      //        : false,
      //  });
    }
  }, [dispatch, id, reset]);

  const handleStudentType = useCallback(() => {
    let day = watchDay;
    let month = watchMonth;
    let year = watchYear;
    if (day && month && year) {
      const date1 = new Date();
      const date2 = new Date(`${month}/${day}/${year}`);
      let diff = (date1.getTime() - date2.getTime()) / 1000;
      diff /= 60 * 60 * 24;
      diff = Math.abs(Math.round(diff / 365.25));

      return diff >= 4 && diff <= 16 ? "child" : "adult";
    }
  }, [watchDay, watchMonth, watchYear]);

  const getMembershipListings = useCallback(
    (type, discipline) => {
      const st_type = type ? type : handleStudentType();
      if (st_type && discipline) {
        dispatch(
          FetchMembershipListing({
            page: 1,
            limit: 10000,
            student_type: st_type,
            discipline: discipline,
          })
        );
      }
    },
    [handleStudentType, dispatch]
  );

  useEffect(() => {
    if (yearList?.length === 0) {
      let minYear = moment().subtract(4, "years").format("YYYY");
      let years = [];
      for (let i = 1920; i <= minYear; i++) {
        years.push(i?.toString());
      }
      years.sort((a, b) => b - a);
      setYearList(years);
    }
  }, [yearList?.length]);

  const stud_typ = () => {
    console.log(watchDay, watchMonth, watchYear);
    if (watchDay && watchMonth && watchYear) {
      const date1 = new Date();
      const date2 = new Date(`${watchMonth}/${watchDay}/${watchYear}`);
      let diff = (date1.getTime() - date2.getTime()) / 1000;
      diff /= 60 * 60 * 24;
      diff = Math.abs(Math.round(diff / 365.25));
      console.log(diff);

      return diff >= 4 && diff <= 16 ? "child" : "adult";
    }
  };

  let stType = stud_typ();

  return (
    <section className="bg-image">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="logo-bg position-relative">
              <p>
                <img
                  src="assets/img/headerlogo.png"
                  className="logo-icon"
                  alt="logo"
                />
              </p>
              <p>
                <img
                  src="assets/img/banner_logo.png"
                  className="logo-img"
                  alt="logo"
                />
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="form-style">
              <form className="row" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                  <h2 className="mdl-ttl">add student details</h2>

                  {((transactionId && user?.student_list?.length > 0) ||
                    user?.student_list?.length > 0) && (
                    <div className="form-group col-sm-12 col-md-12 text-end ">
                      <span
                        className="theme-color fw-bold cursor-pointer"
                        onClick={() => {
                          navigate("/dashboard", { replace: "true" });
                          console.log("clicked");
                        }}
                      >
                        Skip
                      </span>
                    </div>
                  )}
                </div>

                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center">
                  <div className="upload-btn-wrapper ">
                    <button className="btn">
                      {time > 0 && isActive ? (
                        <Progress type="circle" width={50} percent={time} />
                      ) : (
                        <img
                          src={
                            getValues("image")
                              ? getValues("image")
                              : "assets/img/Stdt1.png"
                          }
                          alt=""
                        />
                      )}

                      {time > 0 && isActive ? null : (
                        <div className="camera-icon">
                          <i className="fa fa-camera"></i>
                        </div>
                      )}
                    </button>

                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        if (e.target.files.length > 0) {
                          handleImage(e);
                        }
                      }}
                    />
                  </div>
                </div>

                {errors.image && (
                  <span className="text-danger">{errors?.image?.message}</span>
                )}

                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="fw-bold">Student First Name</label>

                  <div className="input-container">
                    <input
                      type="text"
                      className="form-control ps-4"
                      placeholder="Student First Name*"
                      {...register("studentFirstName")}
                    />
                    <img
                      src="assets/img/First-Name.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                  {errors.studentFirstName && (
                    <span className="text-danger">
                      {errors?.studentFirstName?.message}
                    </span>
                  )}
                </div>

                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="fw-bold">Student Last Name</label>

                  <div className="input-container">
                    <input
                      type="text"
                      className="form-control ps-4"
                      placeholder="Student Last Name*"
                      {...register("studentLastName")}
                    />
                    <img
                      src="assets/img/First-Name.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                  {errors.studentLastName && (
                    <span className="text-danger">
                      {errors?.studentLastName?.message}
                    </span>
                  )}
                </div>

                {/* <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <div className="input-container">
                    <Controller
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          containerClassName="form-control ps-4"
                          placeholder="Student's Date of Birth*"
                          value={value}
                          onChange={(date) => {
                            onChange(date.toDate());
                          }}
                          maxDate={new Date()}
                        />
                      )}
                      name="dob"
                      control={control}
                    />
                    <img
                      src="assets/img/date-of-birth.png"
                      className="input-img"
                      alt=""
                    />
                    <img
                      src="assets/img/choose-date.png"
                      className="field-icon"
                      alt=""
                    />
                  </div>
                  {errors.dob && (
                    <span className="text-danger">{errors?.dob?.message}</span>
                  )}
                </div> */}
                <div className="form-group col-sm-12 col-md-12 mb-4">
                  <div className="">
                    <label className="fw-bold">Gender</label>

                    <div className="termCustomRadio">
                      <p>
                        <span className="me-3">
                          <Controller
                            name="gender"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <input
                                type="radio"
                                id="male"
                                name="radio-group"
                                checked={value === "male"}
                                onChange={() => {
                                  onChange("male");
                                }}
                              />
                            )}
                          />
                          <label htmlFor="male">Male</label>
                        </span>
                        <span className="me-3">
                          <Controller
                            name="gender"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <input
                                type="radio"
                                id="female"
                                name="radio-group"
                                checked={value === "female"}
                                onChange={() => {
                                  onChange("female");
                                }}
                              />
                            )}
                          />
                          <label htmlFor="female">Female</label>
                        </span>
                        <span className="me-3">
                          <Controller
                            name="gender"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <input
                                type="radio"
                                id="other"
                                name="radio-group"
                                checked={value === "other"}
                                onChange={() => {
                                  onChange("other");
                                }}
                              />
                            )}
                          />
                          <label htmlFor="other">Other</label>
                        </span>
                      </p>
                    </div>
                  </div>
                  {errors?.gender && (
                    <span className="text-danger">
                      {errors?.gender?.message}
                    </span>
                  )}
                </div>
                <div className="col-sm-12">
                  <label className="fw-bold">Date Of Birth</label>
                  {errors && (
                    <span className="text-danger">
                      {errors?.student_type?.message}
                    </span>
                  )}
                </div>

                <div className="form-group col-sm-12 col-md-4 col-lg-4 mb-4">
                  <div className="input-container">
                    <Controller
                      name="year"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <select
                          onChange={(e) => {
                            setValue("year", e.target.value);
                            // handleStudentType(
                            //   watchDay,
                            //   watchMonth,
                            //   e.target.value
                            // );
                            let st;
                            if (watchDay && watchMonth && e.target.value) {
                              const date1 = new Date();
                              const date2 = new Date(
                                `${watchMonth}/${watchDay}/${e.target.value}`
                              );
                              let diff =
                                (date1.getTime() - date2.getTime()) / 1000;
                              diff /= 60 * 60 * 24;
                              diff = Math.abs(Math.round(diff / 365.25));
                              console.log(diff);

                              st = diff >= 4 && diff <= 16 ? "child" : "adult";
                            }
                            getMembershipListings(st, watchDiscipline);
                          }}
                          value={value}
                          className="form-control ps-4"
                        >
                          <option value="">Select Year</option>
                          {/* {years?.map((item, i) => (
                            <option value={item} key={i}>
                              {item}
                            </option>
                          ))} */}
                          {/* <option value="2022">2022</option>
                          <option value="2021">2021</option>
                          <option value="2020">2020</option>
                          <option value="2019">2019</option>
                          <option value="2018">2018</option>
                          <option value="2017">2017</option>
                          <option value="2016">2016</option>
                          <option value="2015">2015</option>
                          <option value="2014">2014</option>
                          <option value="2013">2013</option>
                          <option value="2012">2012</option>
                          <option value="2011">2011</option>
                          <option value="2010">2010</option>
                          <option value="2009">2009</option>
                          <option value="2008">2008</option>
                          <option value="2007">2007</option>
                          <option value="2006">2006</option>
                          <option value="2005">2005</option>
                          <option value="2004">2004</option>
                          <option value="2003">2003</option>
                          <option value="2002">2002</option>
                          <option value="2001">2001</option>
                          <option value="2000">2000</option>

                          <option value="1999">1999</option>
                          <option value="1998">1998</option>
                          <option value="1997">1997</option>
                          <option value="1996">1996</option>
                          <option value="1995">1995</option>
                          <option value="1994">1994</option>
                          <option value="1993">1993</option>
                          <option value="1992">1992</option>
                          <option value="1991">1991</option>
                          <option value="1990">1990</option>
                          <option value="1989">1989</option>
                          <option value="1987">1987</option>
                          <option value="1986">1986</option>
                          <option value="1985">1985</option>
                          <option value="1984">1984</option>
                          <option value="1983">1983</option>
                          <option value="1982">1982</option>
                          <option value="1981">1981</option>
                          <option value="1980">1980</option> */}

                          {yearList?.map((item) => {
                            return (
                              <option value={item} key={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      )}
                    />

                    <img
                      src="assets/img/date-of-birth.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                  {errors && (
                    <span className="text-danger">{errors?.year?.message}</span>
                  )}
                </div>

                <div className="form-group col-sm-12 col-md-4 col-lg-4 mb-4">
                  <div className="input-container">
                    <Controller
                      name="month"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <select
                          onChange={(e) => {
                            setValue("month", e.target.value);
                            // handleStudentType(
                            //   watchDay,
                            //   e.target.value,
                            //   watchYear
                            // );

                            let st;
                            if (watchDay && e.target.value && watchYear) {
                              const date1 = new Date();
                              const date2 = new Date(
                                `${e.target.value}/${watchDay}/${watchYear}`
                              );
                              let diff =
                                (date1.getTime() - date2.getTime()) / 1000;
                              diff /= 60 * 60 * 24;
                              diff = Math.abs(Math.round(diff / 365.25));
                              console.log(diff);

                              st = diff >= 4 && diff <= 16 ? "child" : "adult";
                            }
                            getMembershipListings(st, watchDiscipline);
                          }}
                          value={value}
                          className="form-control ps-4"
                        >
                          <option value="">Select Month</option>
                          {monthOption?.map((item) => (
                            <option value={item?.id} key={item?.id}>
                              {item?.value}
                            </option>
                          ))}
                        </select>
                      )}
                    />

                    <img
                      src="assets/img/date-of-birth.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                  {errors && (
                    <span className="text-danger">
                      {errors?.month?.message}
                    </span>
                  )}
                </div>

                <div className="form-group col-sm-12 col-md-4 col-lg-4 mb-4">
                  <div className="input-container">
                    <Controller
                      name="day"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <select
                          onChange={(e) => {
                            setValue("day", e.target.value);
                            // handleStudentType(
                            //   e.target.value,

                            //   watchMonth,
                            //   watchYear
                            // );

                            let st;
                            if (e.target.value && watchMonth && watchYear) {
                              const date1 = new Date();
                              const date2 = new Date(
                                `${watchMonth}/${e.target.value}/${watchYear}`
                              );
                              let diff =
                                (date1.getTime() - date2.getTime()) / 1000;
                              diff /= 60 * 60 * 24;
                              diff = Math.abs(Math.round(diff / 365.25));
                              console.log(diff);

                              st = diff >= 4 && diff <= 16 ? "child" : "adult";
                            }
                            getMembershipListings(st, watchDiscipline);
                          }}
                          value={value}
                          className="form-control ps-4"
                        >
                          <option value={""}>Select Day</option>
                          {[...Array(watchMonth === "2" ? 28 : 31).keys()].map(
                            (item, i) => {
                              return (
                                <option value={item + 1} key={i}>
                                  {item + 1}
                                </option>
                              );
                            }
                          )}
                        </select>
                      )}
                    />

                    <img
                      src="assets/img/date-of-birth.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                  {errors && (
                    <span className="text-danger">{errors?.day?.message}</span>
                  )}
                </div>

                <div
                  className="form-group col-sm-12 col-md-12 col-lg-12 mb-4"
                  style={{ display: "none" }}
                >
                  <label className="fw-bold">Student Type</label>

                  <div className="checkboxs AddStdtCheck">
                    <label>
                      <Controller
                        name="student_type"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <input
                            type="radio"
                            value="child"
                            name="student_type"
                            checked={value === "child"}
                            onChange={() => {
                              onChange("child");
                              getMembershipListings("child", watchDiscipline);
                            }}
                          />
                        )}
                      />
                      <div className="div-bg">
                        <p>
                          <span>child</span>
                        </p>
                      </div>
                    </label>

                    <label>
                      <Controller
                        name="student_type"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <input
                            type="radio"
                            value="adult"
                            name="student_type"
                            checked={value === "adult"}
                            onChange={() => {
                              onChange("adult");
                              getMembershipListings("adult", watchDiscipline);
                            }}
                          />
                        )}
                      />
                      <div className="div-bg">
                        <p>
                          <span>adult</span>
                        </p>
                      </div>
                    </label>
                  </div>
                  {errors.student_type && (
                    <span className="text-danger">
                      {errors?.student_type?.message}
                    </span>
                  )}
                </div>

                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="fw-bold mb-2">Discipline</label>
                  <div className="checkboxs AddStdtCheck">
                    {disciplines
                      ?.filter(
                        (it) =>
                          it?.name?.toLowerCase() !== "all disciplines" ||
                          it?.id !== 6
                      )
                      ?.map((disciplin, i) => {
                        return (
                          <label key={i}>
                            <Controller
                              name="discipline"
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <input
                                  type="radio"
                                  value={disciplin?.id}
                                  name="discipline"
                                  checked={value === disciplin?.id}
                                  onChange={() => {
                                    onChange(disciplin?.id);
                                    let st = stud_typ();

                                    getMembershipListings(st, disciplin?.id);
                                  }}
                                />
                              )}
                            />
                            <div className="div-bg">
                              <p>
                                <span>{disciplin?.name}</span>
                              </p>
                            </div>
                          </label>
                        );
                      })}
                    {/* <label>
                      <Controller
                        name="discipline"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <input
                            type="radio"
                            value="kick boxing"
                            name="discipline"
                            checked={value === "kick boxing"}
                            onChange={() => {
                              onChange("kick boxing");
                              getMembershipListings(
                                watchStudentType,
                                "kick boxing"
                              );
                            }}
                          />
                        )}
                      />
                      <div className="div-bg">
                        <p>
                          <span>kick boxing</span>
                        </p>
                      </div>
                    </label> */}
                  </div>
                  {errors.discipline && (
                    <span className="text-danger">
                      {errors?.discipline?.message}
                    </span>
                  )}
                </div>

                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="fw-bold mb-2">Membership</label>
                  <div className="checkboxs AddStdtCheck">
                    <label>
                      <Controller
                        name="trial_membership"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <input
                            type="radio"
                            checked={value === "1"}
                            name="trial_membership"
                            onChange={() => {
                              setValue("trial_membership", "1");
                              setValue("membership_level", "");
                            }}
                          />
                        )}
                      />
                      <div className="div-bg">
                        <p>
                          <span>Pay For Trial Lessons</span>
                        </p>
                      </div>
                    </label>
                    <label>
                      <Controller
                        name="trial_membership"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <input
                            type="radio"
                            checked={value === "3"}
                            name="trial_membership"
                            onChange={() => {
                              setValue("trial_membership", "3");
                            }}
                          />
                        )}
                      />
                      <div className="div-bg">
                        <p>
                          <span>Buy Membership</span>
                        </p>
                      </div>
                    </label>
                  </div>
                  {errors.trialAccountStatus && (
                    <span className="text-danger">
                      {errors?.trialAccountStatus?.message}
                    </span>
                  )}
                </div>

                {watchTrailMembership === "3" && (
                  <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                    <label className="fw-bold mb-2">Membership Type</label>
                    <div className="input-container">
                      <Controller
                        name="membership_level"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <select
                            name="membership_level"
                            value={value}
                            className="form-control ps-4"
                            onChange={(e) => {
                              onChange(e);
                            }}
                          >
                            <option value="">Select membership</option>
                            {membershipLists?.map((item) => (
                              <option value={item?.id} key={item?.id}>
                                {item?.name}
                              </option>
                            ))}
                          </select>
                        )}
                      />
                      <img
                        src="assets/img/address.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                    {errors.membership_level && (
                      <span className="text-danger">
                        {errors?.membership_level?.message}
                      </span>
                    )}
                  </div>
                )}
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="fw-bold">Area</label>

                  <div className="input-container">
                    <Controller
                      name="areacode"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        // <GooglePlace
                        //   name="address"
                        //   value={value}
                        //   latLong={{
                        //     lat: watchLat,
                        //     lng: watchLng,
                        //   }}
                        //   onChange={(val, latLong) => {

                        //     if (val) {
                        //       onChange(val);
                        //       setValue("latitude", latLong.lat);
                        //       setValue("longitude", latLong.lng);
                        //     } else {
                        //       setValue("latitude", "");
                        //       setValue("longitude", "");
                        //       setValue("location", "");
                        //     }
                        //   }}
                        // />
                        <select
                          name="areacode"
                          value={value}
                          className="form-control ps-4"
                          onChange={onChange}
                        >
                          <option value="">Select Area</option>
                          <option value="uk">uk</option>
                          {areaCodes?.map((item) => (
                            <option value={item?.id} key={item?.id}>
                              {item?.areacode}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                    <img
                      src="assets/img/address.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                  {errors.areacode && (
                    <span className="text-danger">
                      {errors?.areacode?.message}
                    </span>
                  )}
                </div>

                {watchAreaCode && (
                  <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                    <label className="fw-bold">Venue</label>

                    <div className="input-container">
                      <Controller
                        name="schoolId"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          // <GooglePlace
                          //   name="address"
                          //   value={value}
                          //   latLong={{
                          //     lat: watchLat,
                          //     lng: watchLng,
                          //   }}
                          //   onChange={(val, latLong) => {

                          //     if (val) {
                          //       onChange(val);
                          //       setValue("latitude", latLong.lat);
                          //       setValue("longitude", latLong.lng);
                          //     } else {
                          //       setValue("latitude", "");
                          //       setValue("longitude", "");
                          //       setValue("location", "");
                          //     }
                          //   }}
                          // />
                          <select
                            name="schoolId"
                            value={value}
                            className="form-control ps-4"
                            onChange={onChange}
                          >
                            <option value="">Select Venue</option>
                            <option value="uk">Venue</option>
                            {schoolList?.map((item) => {
                              if (item?.areacode !== null) {
                                return (
                                  <option value={item?.id} key={item?.id}>
                                    {item?.name}
                                  </option>
                                );
                              } else {
                                return null;
                              }
                            })}
                          </select>
                        )}
                      />
                      <img
                        src="assets/img/address.png"
                        className="input-img"
                        alt=""
                      />
                    </div>
                    {errors.schoolId && (
                      <span className="text-danger">
                        {errors?.schoolId?.message}
                      </span>
                    )}
                  </div>
                )}
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="fw-bold">Emergency Telephone Number</label>

                  <div className="input-container">
                    <Controller
                      name="mobile"
                      control={control}
                      defaultValue={null}
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                      }) => (
                        <Phone
                          onChange={onChange}
                          value={value}
                          className="form-control ps-4"
                          setValue={setValue}
                          name="mobile"
                          isValid={isValid}
                        />
                      )}
                    />
                    <img
                      src="assets/img/mobile.png"
                      className="input-img"
                      alt=""
                    />
                  </div>
                  {errors.mobile && (
                    <span className="text-danger">
                      {errors?.mobile?.message}
                    </span>
                  )}
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="mb-2 fw-bold d-flex justify-content-between">
                    <span>
                      {/* <img src="assets/img/medical.png" alt="" />  */}
                      Medical Note
                    </span>
                    {/* <small className="text-muted">Optional</small> */}
                  </label>
                  <div className="input-container">
                    <textarea
                      className="form-control p-3"
                      rows="7"
                      placeholder="Write here..."
                      {...register("note")}
                    ></textarea>
                  </div>
                  {errors.note && (
                    <span className="text-danger">{errors?.note?.message}</span>
                  )}
                </div>
                {/* <div className="form-group col-sm-12 col-md-12 text-center">
                  <span className="fw-bold cursor-pointer">
                    By clicking below, you agree with the{" "}
                    <span
                      className="theme-color fw-bold"
                      onClick={() => {
                        navigate("/terms-and-condition/add new student");
                      }}
                    >
                      TERMS & CONDITION
                    </span>
                    .
                  </span>
                </div> */}

                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center ">
                  <div className="input-container term-checkbox ">
                    <Controller
                      name="tnc"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <input
                          type="checkbox"
                          id="termCheckbox"
                          name="tnc"
                          checked={value}
                          // {...register("tnc")}
                          onChange={(e) => {
                            onChange(e.target.checked);
                          }}
                        />
                      )}
                    />
                    <label htmlFor="termCheckbox"></label>
                    <span>
                      I accept the{" "}
                      <span
                        className="theme-color fw-bold"
                        onClick={() => {
                          // navigate("/terms-and-condition/add new student");
                          window.open(
                            "/terms-and-condition/add new student",
                            "_blank"
                          );
                        }}
                      >
                        TERMS & CONDITIONS
                      </span>{" "}
                      applied for adding new student. .
                    </span>
                    {errors && (
                      <div className="text-red">{errors?.tnc?.message}</div>
                    )}
                  </div>
                </div>

                <div className="form-group col-sm-12 col-md-12 text-center">
                  <button className="btn btn-submit" type="submit">
                    Done
                  </button>
                </div>
              </form>
            </div>
            {/* {show && (
              <MembershipLevelModal
                show={show}
                handleShow={() => {
                  setShow(!show);
                }}
                data={{
                  level: watch("membershipLevel"),
                  discipline: watch("discipline"),
                }}
                studentId={studentId}
              />
            )} */}

            {/* <BuyMembershipPayment
              show={showPayment}
              handleShow={() => {
                setShowPayment(!showPayment);
              }}
              showAnother={setShowAnother}
            /> */}

            <WantToAddAnotherStudent
              show={showAnother}
              handleShow={setShowAnother}
              addAnotherStudent={() => {
                setShowAnother(false);
                reset();
              }}
            />

            {showBuyMembership && watchMembershipLevel && (
              <BuyMembershipModal
                show={showBuyMembership}
                handleShow={setShowBuyMembership}
                discipline={watchDiscipline}
                student_type={stType}
                studentId={id}
                handleBuy={handleBuy}
                membershipId={watchMembershipLevel}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AddStudentDetails;
