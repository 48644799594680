import { Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPasswordSchema } from "../../utils/schema";
import { useDispatch } from "react-redux";
import { resetPasswordCall } from "../slice";
import { toast } from "react-toastify";
import Loader from "../../component/Loader";
import BackButton from "./../../component/BackButton";
function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [passwordFor, setPasswordFor] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    control,
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    resolver: yupResolver(resetPasswordSchema),
    shouldFocusError: true,
  });

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(
      resetPasswordCall({ password: data?.password, user_type: "teacher" })
    ).then(async (res) => {
      setLoading(false);
      if (res?.payload?.code === 1) {
        await localStorage.setItem("token", res?.payload?.data?.token);
        navigate("/login", { replace: true });
        toast.success(res?.payload?.message);
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  return (
    <section className="bg-image">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="logo-bg position-relative">
              <p className="cursor-pointer">
                <img
                  src="assets/img/headerlogo.png"
                  className="img-fluid cursor-pointer"
                  alt="logo"
                  onClick={() => {
                    navigate("/");
                  }}
                />
              </p>
              <p>
                <img
                  src="assets/img/banner_logo.png"
                  className="logo-img"
                  alt="logo"
                />
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 mt-5">
            <BackButton />
            <div className="form-style mt-5">
              <form className="row" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group col-sm-12 col-md-12 text-center mb-5">
                  <div className="">
                    <img
                      src="assets/img/change-password.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div className="form-group col-sm-12 col-md-12 text-center mb-4">
                  <h2 className="mdl-ttl">reset password</h2>
                  <p>Please reset your password here</p>
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="fw-bold">New Password</label>

                  <div className="input-container">
                    <input
                      type={
                        toggle && passwordFor === "setPassword"
                          ? "text"
                          : "password"
                      }
                      className="form-control ps-4"
                      placeholder="Set New Password"
                      name="password"
                      {...register("password")}
                    />
                    <img
                      src="assets/img/password.png"
                      className="input-img"
                      alt=""
                    />
                    <span
                      onClick={() => {
                        setPasswordFor("setPassword");
                        setToggle(!toggle);
                      }}
                      className="pass-view field-icon toggle-password cursor-pointer"
                    ></span>
                  </div>
                  {errors?.password && (
                    <div className="text-red">{errors?.password?.message}</div>
                  )}
                </div>
                <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label className="fw-bold">Confirm Password</label>

                  <div className="input-container">
                    <input
                      type={
                        toggle && passwordFor === "confrimPassword"
                          ? "text"
                          : "password"
                      }
                      className="form-control ps-4"
                      placeholder="Set Confirm New Password"
                      name="confirmPassword"
                      {...register("confirmPassword")}
                    />
                    <img
                      src="assets/img/password.png"
                      className="input-img"
                      alt=""
                    />
                    <span
                      onClick={() => {
                        setPasswordFor("confrimPassword");
                        setToggle(!toggle);
                      }}
                      className="pass-view field-icon toggle-password cursor-pointer"
                    ></span>
                  </div>
                  {errors?.confirmPassword && (
                    <div className="text-red">
                      {errors?.confirmPassword?.message}
                    </div>
                  )}
                </div>
                <div className="form-group col-sm-12 col-md-12 text-center">
                  <button className="btn btn-submit" type="submit">
                    {loading ? <Loader /> : "Done"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ResetPassword;
