import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ClassBookingCard from "../../component/ClassBookingCard";
import EventBookingCard from "../../component/EventBookingCard";
import Loader from "../../component/Loader";
import BookingDetailModal from "../../component/Modal/BookingDetailModal";
import Paginations from "../../component/Pagination";
import moment from "moment";
import {
  bookingCountSelector,
  bookingListSelector,
  FetchBookingList,
  schoolListSelector,
} from "../slice";

function Bookings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [booking_for, setBooking_for] = useState("class");
  const [schoolId, setSchoolId] = useState("");
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const list = useSelector(bookingListSelector);
  const total = useSelector(bookingCountSelector);
  const schoolList = useSelector(schoolListSelector);
  const [type, setType] = useState("future");
  const [status, setStatus] = useState("1");
  const [loading, setLoading] = useState(false);
  const [classes, setClasses] = useState([]);
  const [events, setEvents] = useState([]);

  const [selectedBooking, setSelectedBooking] = useState(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(
      FetchBookingList({
        page: page,
        limit: 10,
        search: search,
        booking_for,
        school_id: schoolId,
        type,
        status,
      })
    ).then((res) => {
      setLoading(false);
      if (res?.payload?.code === 1) {
        if (booking_for === "class") {
          setClasses(res?.payload?.data?.booking_list);
        }
        if (booking_for === "event") {
          setEvents(res?.payload?.data?.booking_list);
        }
      }
    });
  }, [dispatch, page, search, booking_for, schoolId, type]);

  return (
    <section className="sec-bg py-4 px-3 bottom-space">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-12 col-lg-12 mt-3 text-center">
            <h3>My Bookings</h3>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 mt-3 text-center">
            <ul
              className="nav nav-pills mb-3 d-flex justify-content-center gap-3"
              id="pills-tabContent"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className={
                    booking_for === "class"
                      ? "btn bookingPills shadow nav-link active"
                      : "btn bookingPills shadow nav-link"
                  }
                  id="classTab-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#classTab"
                  type="button"
                  role="tab"
                  aria-controls="classTab"
                  aria-selected="true"
                  onClick={() => {
                    setPage(1);
                    setBooking_for("class");
                  }}
                >
                  Class
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={
                    booking_for === "event"
                      ? "btn bookingPills shadow nav-link active"
                      : "btn bookingPills shadow nav-link"
                  }
                  id="eventTab-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#eventTab"
                  type="button"
                  role="tab"
                  aria-controls="eventTab"
                  aria-selected="false"
                  onClick={() => {
                    setPage(1);
                    setBooking_for("event");
                  }}
                >
                  Event
                </button>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-8 mt-3">
            <ul className="nav nav-pills booking-tabs mt-4 mr-2 pb-2">
              <li className="nav-item ml-20">
                <button
                  className={type === "future" ? "nav-link active" : "nav-link"}
                  onClick={() => {
                    setType("future");
                    setStatus("1");
                  }}
                >
                  future
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={type === "past" ? "nav-link active" : "nav-link"}
                  onClick={() => {
                    setType("past");
                    setStatus("1");
                  }}
                >
                  past
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={
                    type === "cancelled" ? "nav-link active" : "nav-link"
                  }
                  onClick={() => {
                    setType("cancelled");
                    setStatus("2");
                  }}
                >
                  cancelled
                </button>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 mt-3">
            <div className="teacher-fil">
              <h6 className="ps-4">Filter By</h6>
              <div className="bg-white py-3 px-3 position-relative">
                <select
                  className="border-0 w-80 form-select"
                  value={schoolId}
                  onChange={(e) => {
                    setSchoolId(e.target.value);
                  }}
                >
                  <option value="">Filter By Venue</option>
                  {schoolList?.map((item, i) => (
                    <option value={item?.id} key={i}>
                      {item?.name}
                    </option>
                  ))}
                </select>
                <img
                  src="assets/img/filter.png"
                  className="filter-icon rounded"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 mt-3">
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="classTab"
                role="tabpanel"
                aria-labelledby="classTab-tab"
              >
                {/* {loading ? (
                  <Loader />
                ) : (
                  <div className="row">
                    {classes?.length > 0 ? (
                      classes?.map((item, i) => {
                        return (
                          <div
                            className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3 cursor-pointer"
                            key={i}
                          >
                            <ClassBookingCard
                              data={item?.booking_classes[0]}
                              amount={item?.total_amount}
                              total={item?.booking_classes?.length || 0}
                              classItem={item}
                              setShow={setShow}
                              setSelectedBooking={setSelectedBooking}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <div className="text-center">No data found</div>
                    )}
                  </div>
                )} */}
                <div className="table-responsive text-center">
                  <table className="table storetable">
                    <thead className="text-center">
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Time</th>
                        <th scope="col">Students</th>
                        <th scope="col">Discipline</th>
                        <th scope="col">Class</th>
                        <th scope="col">Venue</th>
                        <th scope="col">Instructor</th>
                        <th scope="col">Address</th>
                        {/* <th scope="col">Payment Status</th> */}
                        <th scope="col">action</th>
                      </tr>
                      <tr className="border-0 bg-none invisible">
                        <td className="border-0 bg-none"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {classes?.length > 0 ? (
                        classes?.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                {item?.date
                                  ? moment(item?.date).format("DD MMM YYYY")
                                  : "NA"}
                              </td>
                              {booking_for === "class" && (
                                <td>
                                  {item?.start_time
                                    ? `${moment(
                                        item?.start_time,
                                        "hh:mm:ss"
                                      ).format("hh:mm a")}-${moment(
                                        item?.end_time,
                                        "hh:mm:ss"
                                      ).format("hh:mm a")}`
                                    : "NA"}
                                </td>
                              )}

                              <td>
                                {item?.student_list
                                  ?.map((st, ind) => {
                                    return `${st?.student_details?.first_name} ${st?.student_details?.last_name}`;
                                  })
                                  ?.join(", ")}
                              </td>
                              <td>
                                {" "}
                                {item?.booking_classes?.class_details
                                  ?.discipline_details?.name || ""}
                              </td>
                              <td>
                                {" "}
                                {item?.booking_classes?.class_details?.name ||
                                  ""}
                              </td>

                              <td>
                                {" "}
                                {item?.booking_classes?.school_details
                                  ? `${item?.booking_classes?.school_details?.name}`
                                  : ""}
                              </td>
                              <td>
                                {" "}
                                {item?.booking_classes?.class_details
                                  ?.assigned_teacher?.teacher_details
                                  ?.full_name || ""}
                              </td>
                              <td>
                                {" "}
                                {item?.booking_classes?.school_details
                                  ?.address || ""}
                              </td>
                              {/* <td>
                                {" "}
                                <span>
                                  {" "}
                                  {booking_for === "class" && item?.booking_classes?.booking_details
                                    ?.payment_status === "0"
                                    ? "Failed"
                                    : "Completed"}
                                </span>
                                {item?.booking_classes?.booking_details
                                  ?.payment_status === "0" && (
                                  <span className="btn btn-accept btn-pay">
                                    Pay
                                  </span>
                                )}
                              </td> */}
                              <td>
                                <div className="d-flex justify-content-center action">
                                  <button
                                    className="btn"
                                    title="View"
                                    onClick={() => {
                                      // console.log(item);
                                      navigate(
                                        `/booking/${item?.booking_classes?.booking_details?.id}`
                                      );
                                    }}
                                  >
                                    <span>
                                      <img src="assets/img/view.png" alt="" />
                                    </span>
                                  </button>

                                  {/* <button
                                    className="btn"
                                    onClick={() => {
                                      handleModal("delete");
                                      setDelId(item.id);
                                    }}
                                    title="Delete"
                                  >
                                    <img
                                      src="assets/images/delete.png"
                                      alt=""
                                    />
                                  </button> */}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={10}>No data found.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="eventTab"
                role="tabpanel"
                aria-labelledby="eventTab-tab"
              >
                {/* {loading ? (
                  <Loader />
                ) : (
                  <div className="row">
                    {events?.length > 0 ? (
                      events?.map((item, i) => {
                        return (
                          <div
                            className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3"
                            key={i}
                          >
                            <EventBookingCard
                              data={item?.booking_events?.[0]}
                              amount={
                                item?.booking_events?.[0]?.event_details
                                  ?.event_price || 0
                              }
                              total={item?.booking_events?.length || 0}
                              eventItem={item}
                              setShow={setShow}
                              setSelectedBooking={setSelectedBooking}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <div className="text-center">No data found</div>
                    )}
                  </div>
                )} */}

                <div className="table-responsive text-center">
                  <table className="table storetable">
                    <thead className="text-center">
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Time</th>
                        <th scope="col">Students</th>
                        <th scope="col">Discipline</th>
                        <th scope="col">Event</th>
                        <th scope="col">Venue</th>
                        <th scope="col">Level Name</th>
                        <th scope="col">Address</th>
                        {/* <th scope="col">Payment Status</th> */}
                        <th scope="col">action</th>
                      </tr>
                      <tr className="border-0 bg-none invisible">
                        <td className="border-0 bg-none"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {events?.length > 0 ? (
                        events?.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                {item?.date
                                  ? moment(item?.date).format("DD MMM YYYY")
                                  : "NA"}
                              </td>
                              {booking_for === "event" && (
                                <td>
                                  {item?.event_details?.start_time
                                    ? moment(
                                        item?.event_details?.start_time,
                                        "hh:mm:ss"
                                      ).format("hh:mm a")
                                    : "NA"}
                                </td>
                              )}

                              <td>
                                {item?.student_list
                                  ?.map((st, ind) => {
                                    return `${st?.student_details?.first_name} ${st?.student_details?.last_name}`;
                                  })
                                  ?.join(", ")}
                              </td>
                              <td>
                                {" "}
                                {item?.student_list?.[0]?.student_details
                                  ?.discipline_details?.name || ""}
                              </td>
                              <td> {item?.event_details?.name || ""}</td>

                              <td>
                                {" "}
                                {item?.school_details
                                  ? `${item?.school_details?.name}`
                                  : ""}
                              </td>
                              <td>
                                {" "}
                                {item?.level_details
                                  ? item?.level_details?.name
                                  : "NA"}
                              </td>
                              <td> {item?.school_details?.address || ""}</td>
                              {/* <td>
                                {" "}
                                {item?.booking_details?.payment_status === "0"
                                  ? "Failed"
                                  : "Completed"}
                              </td> */}
                              <td>
                                <div className="d-flex justify-content-center action">
                                  <button
                                    className="btn"
                                    title="View"
                                    onClick={() => {
                                      // console.log(item);
                                      navigate(
                                        `/booking/${item?.booking_details?.id}`
                                      );
                                    }}
                                  >
                                    <span>
                                      <img src="assets/img/view.png" alt="" />
                                    </span>
                                  </button>

                                  {/* <button
                                    className="btn"
                                    onClick={() => {
                                      handleModal("delete");
                                      setDelId(item.id);
                                    }}
                                    title="Delete"
                                  >
                                    <img
                                      src="assets/images/delete.png"
                                      alt=""
                                    />
                                  </button> */}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={10}>No data found.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="mt-2">
              {total > 10 && (
                <Paginations page={page} handlePage={setPage} total={total} />
              )}
            </div>
          </div>
        </div>
      </div>
      {selectedBooking && (
        <BookingDetailModal
          show={show}
          handleShow={setShow}
          data={selectedBooking}
          setSelectedBooking={setSelectedBooking}
        />
      )}
    </section>
  );
}

export default Bookings;
