import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import ReadMore from "../../component/ReadMore/ReadMore.jsx";
import { FetchMembershipListing } from "../membership/MembershipSlice.js";
import moment from "moment";
import { addMemberShip, addStudentCall, userDetailSelector } from "../slice.js";
import BuyMembershipPayment from "../../component/Modal/BuyMembershipPayment.jsx";
import { toast } from "react-toastify";
import Loader from "../../component/Loader.jsx";
import { addStudentDataReducer } from "../student/StudentSlice.js";
import { postFormData } from "../../api/index.js";
import { sendMembershipSticker } from "../../api/membership.js";
import { config, htmlStringToPdf } from "../../utils/index.js";
import S3 from "aws-s3";
import { v4 as uuidv4 } from "uuid";

const S3Client = new S3(config);
window.Buffer = window.Buffer || require("buffer").Buffer;
function ChangePlan() {
  const { id } = useParams();
  const location = useLocation();
  // console.log(location, "Location");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const discipline = useSelector((state) => state.membership.discipline);
  const student_type = useSelector((state) => state.membership.student_type);
  const data = useSelector((state) => state.membership.student_data);
  // console.log(data, "Student data");

  const user = useSelector(userDetailSelector);
  const list = useSelector((state) => state.membership.list);
  const [showPayment, setShowPayment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState("");
  const [useWalletAmount, setUseWalletAmount] = useState(false);
  const [tnc, setTnc] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedTncIndex, setSelectedTncIndex] = useState("");
  const buyOrRenew = localStorage.getItem("buyOrRenew");

  const [StickerURL, setStickerURL] = useState("");

  console.log(StickerURL, "StickerURL");

  useEffect(() => {
    dispatch(
      FetchMembershipListing({
        page: 1,
        limit: 100000,
        discipline,
        student_type,
      })
    );
  }, [dispatch, discipline, student_type]);

  const onBuy = (memberLevel) => {
    if (!tnc) {
      setErrors("Kindly accept the terms and condition");
    } else {
      let price =
        student_type?.toLowerCase() === "child"
          ? Number(memberLevel?.child_price)
          : student_type?.toLowerCase() === "adult"
          ? Number(memberLevel?.adult_price)
          : 0;

      // console.log(price, "price price pricepricepricepricepriceprice");
      let walletUsedAmount =
        useWalletAmount && Number(user?.wallet) >= price
          ? price
          : useWalletAmount &&
            Number(user?.wallet) > 0 &&
            Number(user?.wallet) <= price
          ? Number(user?.wallet)
          : 0;
      // console.log(
      //   walletUsedAmount,
      //   "walletUsedAmountwalletUsedAmountwalletUsedAmount walletUsedAmountwalletUsedAmountwalletUsedAmount"
      // );
      let transaction_amount = price - walletUsedAmount;
      let payload;

      setLoading(true);

      // console.log(data, memberLevel, "Student data");

      // userMembershipId to be sent to the savePaymentDetail

      const sendHtmlAsString = async (nameD = "") => {
        let htmlPart = `<!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="utf-8">
          <meta http-equiv="Content-Type" content="text/html;charset=UTF-8">
          <meta http-equiv="X-UA-Compatible" content="IE=edge">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Membership Details</title>
      </head>
      <body style="width: 130 mm;height: 90 mm;">
          <div class="mw-body"
              style="width: 100%;height: 100%;
          font-family: Roboto Slab,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji">
              <div>
                  <table style="width: 100%;height: 100%;border-collapse: separate;
                  border-spacing: 0 0em;text-transform: uppercase; font-size: 12px; font-weight: 700;margin: 1mm;padding: 5px;">
                      <tr>
                          <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
          border-right: 0;"><span>name</span></td>
                          <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;"><span style="color: #ff0000;"> ${
                            data?.first_name
                          } ${data?.last_name}</span></td>
                      </tr>
                      <tr>
                          <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                              border-right: 0;"><span>address</span></td>
                          <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;"><span
                                  style="color: #ff0000;"> ${
                                    data?.parent_details?.address_details
                                      ?.property_name
                                  }, ${
          data?.parent_details?.address_details?.property_number
            ? `${data?.parent_details?.address_details?.property_number}, `
            : ""
        } ${data?.parent_details?.address_details?.street_number}</span></td>
                      </tr>
                      <tr>
                          <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                              border-right: 0;"><span>town</span></td>
                          <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                              "><span style="color: #ff0000;"> ${
                                data?.parent_details?.address_details?.town
                              }</span></td>
                      </tr>
                      <tr>
                          <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                              border-right: 0;"><span>postcode</span></td>
                          <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                              "><span style="color: #ff0000;"> ${
                                data?.parent_details?.address_details
                                  ?.postal_code
                              }</span></td>
                      </tr>
                      <tr>
                          <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                              border-right: 0;"><span>dob</span></td>
                          <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                              "><span style="color: #ff0000;"> ${moment(
                                data?.dob
                              ).format("DD MMM YYYY")}</span></td>
                      </tr>
                      <tr>
                          <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                              border-right: 0;"><span>school</span></td>
                          <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                              "><span style="color: #ff0000;"> ${
                                data?.school_details?.name
                              }</span></td>
                      </tr>
                       <tr>
                              <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                                  border-right: 0;"><span>discipline</span></td>
                              <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                                  "><span style="color: #ff0000;"> ${
                                    data?.discipline_details?.name
                                  }</span></td>
                          </tr>
                      <tr>
                          <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                              border-right: 0;"><span>first licence</span></td>
                          <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                              "><span style="color: #ff0000;"> ${moment(
                                data?.membership_details?.start_date
                              ).format("DD MMM YYYY")}</span></td>
                      </tr>
                      <tr>
                          <td style="border-collapse: collapse;padding: 1mm;
                              border-right: 0;"><span>date</span></td>
                          <td style="border-collapse: collapse;padding: 1mm;
                              "><span style="color: #ff0000;"> ${moment(
                                data?.membership_details?.valid_till
                              ).format("DD MMM YYYY")}</span></td>
                      </tr>
                  </table>
              </div>
      </body>
      </html>`;
        const pdfOutput = await htmlStringToPdf(htmlPart);
        return pdfOutput;
      };

      sendHtmlAsString().then((ob) => {
        if (ob) {
          const newFileName = uuidv4();
          S3Client.uploadFile(ob, newFileName)
            .then((data) => {
              setStickerURL(data?.location);

              if (useWalletAmount) {
                payload = {
                  data: {
                    membership_level: memberLevel?.id,
                    studentId: id,
                    wallet_used_amount: walletUsedAmount,
                    sticker_url: data?.location,
                    transaction_amount,
                    payment_status: walletUsedAmount === price ? "1" : "0",
                    currency: "GBP",
                    merchant_support_email: user?.email,
                    student_type: student_type,
                    no_of_days: memberLevel?.no_of_days,
                    price: price,
                    redirect_on: "other",
                  },
                  payment_for: "membership",
                  sub_payment_for: "changePlan",
                  api: "auth/addMemberShipLevel",
                };
              } else {
                payload = {
                  data: {
                    membership_level: memberLevel?.id,
                    studentId: id,
                    // wallet_used_amount: "",
                    sticker_url: data?.location,
                    transaction_amount: price,
                    payment_status: "0",
                    currency: "GBP",
                    merchant_support_email: user?.email,
                    student_type: student_type,
                    no_of_days: memberLevel?.no_of_days,
                    price: price,
                    redirect_on: "other",
                  },
                  payment_for: "membership",
                  sub_payment_for: "changePlan",
                  api: "auth/addMemberShipLevel",
                };
              }

              const isNewMembership =
                !data?.membership_details ||
                data?.membership_details?.membership_level !==
                  payload?.data?.membership_level ||
                data?.trialAccountStatus === "1";

              const isRenewal =
                data?.membership_details?.membership_level ===
                payload?.data?.membership_level;

              console.log(isRenewal, isNewMembership, "Is renew membership");

              console.log(payload, "payload");

              dispatch(addMemberShip(payload?.data)).then((res) => {
                if (res?.payload?.code === 1) {
                  console.log(res, "Add membership data");
                  // setShowPayment(true);

                  if (res?.payload?.data?.payment_link) {
                    setLoading(false);
                    setSelectedIndex("");

                    // toast.success(res?.payload?.message);

                    (async () => {
                      await localStorage.setItem(
                        "bookingMembershipData",
                        JSON.stringify({
                          payment_for: "membership",
                          sub_payment_for:
                            buyOrRenew === "buy" ? "" : "changePlan",
                          userMembershipId:
                            res?.payload?.data?.userMembershipId,
                          studentData: buyOrRenew === "buy" ? data : "",
                          userData: buyOrRenew === "buy" ? user : "",
                        })
                      );
                      dispatch(
                        addStudentDataReducer({
                          payment_for: "membership",
                          sub_payment_for:
                            buyOrRenew === "buy" ? "" : "changePlan",
                          userMembershipId:
                            res?.payload?.data?.userMembershipId,
                          studentData: buyOrRenew === "buy" ? data : "",
                          userData: buyOrRenew === "buy" ? user : "",
                        })
                      );
                    })();
                    let URI =
                      res?.payload?.data?.payment_link?.paymentLink?.url;
                    window.open(URI, "_self");
                  } else {
                    if (isNewMembership) {
                      const stickerPayload = {
                        user_membership_id:
                          res?.payload?.data?.userMembershipId,
                        file_url: data?.location,
                        userId: res?.payload?.data?.user?.id,
                      };
                      sendMembershipSticker(stickerPayload).then((res) => {
                        if (res?.code === 1) {
                          toast.success(res?.message);
                          setLoading(false);
                          setSelectedIndex("");
                          // setShowBuyMembership(false);
                          navigate("/student", { replace: true });
                        } else {
                          toast.error(res?.message);
                        }
                      });
                    } else {
                      toast.success(res?.payload?.message);
                      setLoading(false);
                      setSelectedIndex("");
                      // setShowBuyMembership(false);
                      navigate("/student", { replace: true });
                    }
                  }
                } else {
                  toast.error(res?.payload?.message);
                }
              });
            })
            .catch((err) => console.error(err));
        }
      });
    }
  };

  const handleRenewalDate = (data) => {
    const expiryDate = moment(data?.valid_till);
    const today = moment();
    let difference = expiryDate.diff(today, "days");
    // console.log(difference, "Dif");

    if (difference <= 30 && difference >= 1) {
      return true;
    }

    return false;
  };

  const csaca = () => {
    const sendHtmlAsString = async (nameD = "") => {
      let htmlPart = `<!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="utf-8">
      <meta http-equiv="Content-Type" content="text/html;charset=UTF-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Membership Details</title>
  </head>
  <body style="width: 130 mm;height: 90 mm;">
      <div class="mw-body"
          style="width: 100%;height: 100%;
      font-family: Roboto Slab,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji">
          <div>
              <table style="width: 100%;height: 100%;border-collapse: separate;
              border-spacing: 0 0em;text-transform: uppercase; font-size: 12px; font-weight: 700;margin: 1mm;padding: 5px;">
                  <tr>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
      border-right: 0;"><span>name</span></td>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;"><span style="color: #ff0000;"> sdd ekjfuiwebf eu jvhbedv s</span></td>
                  </tr>
                  <tr>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          border-right: 0;"><span>address</span></td>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;"><span
                              style="color: #ff0000;">dcd eihfb dvbkv ekvubf vf</span></td>
                  </tr>
                  <tr>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          border-right: 0;"><span>town</span></td>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          "><span style="color: #ff0000;"> dc rivhbr dvjbrvk ejvbdjvdfv bfvfdv</span></td>
                  </tr>
                  <tr>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          border-right: 0;"><span>postcode</span></td>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          "><span style="color: #ff0000;">fvfdkjfb rfjvgrev rdbv rdjvbidjv dd</span></td>
                  </tr>
                  <tr>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          border-right: 0;"><span>dob</span></td>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          "><span style="color: #ff0000;">11/05</span></td>
                  </tr>
                  <tr>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          border-right: 0;"><span>school</span></td>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          "><span style="color: #ff0000;">dfkvlhdrbvjjkrhvkrdvbidkvdfvdf dsc</span></td>
                  </tr>
                   <tr>
                          <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                              border-right: 0;"><span>discipline</span></td>
                          <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                              "><span style="color: #ff0000;">lkdifv vjbrv rkdjvb mvd v deecfd</span></td>
                      </tr>
                  <tr>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          border-right: 0;"><span>first licence</span></td>
                      <td style="border-collapse: collapse;padding: 1mm;border-bottom: 0;
                          "><span style="color: #ff0000;">kjrdfb rejvhrej vhrjbvrvbur edcs</span></td>
                  </tr>
                  <tr>
                      <td style="border-collapse: collapse;padding: 1mm;
                          border-right: 0;"><span>date</span></td>
                      <td style="border-collapse: collapse;padding: 1mm;
                          "><span style="color: #ff0000;">dkrf rjvr vjrdbv jvb ds</span></td>
                  </tr>
              </table>
          </div>
  </body>
  </html>`;
      const pdfOutput = await htmlStringToPdf(htmlPart);
      return pdfOutput;
    };

    sendHtmlAsString().then((ob) => {
      if (ob) {
        const newFileName = uuidv4();

        // S3Client.uploadFile(ob, newFileName)
        //   .then((data) => {
        //     console.log(data.location, "S3 data");
        //     // console.log(res, "S3 data");
        //   })
        //   .catch((err) => console.error(err));
      }
    });
  };

  return (
    <section className="sec-bg py-4 px-3">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-12 col-lg-12 mb-3 text-center">
            <h3>Change Plan</h3>
            {/* <span
              onClick={() => {
                csaca();
              }}
              role="button"
            >
              Check
            </span> */}
          </div>
        </div>

        <div className="row">
          {list?.length > 0 ? (
            list
              ?.filter((it) => {
                if (buyOrRenew === "renew" && location?.state?.membershipId) {
                  return it?.id === location?.state.membershipId;
                } else {
                  return it?.id;
                }
              })
              ?.map((item, i) => {
                const isRenewal = handleRenewalDate(item);

                return (
                  <div
                    className="col-sm-12 col-md-6 col-lg-4 mt-5 text-center"
                    key={item?.id}
                  >
                    <div className="membership-step-box h-auto">
                      <div className="">
                        <img
                          src={
                            item?.image?.includes("http")
                              ? item?.image
                              : "assets/img/membership-icon.png"
                          }
                          className="membership-icon"
                          alt=""
                        />
                      </div>
                      <h5 className="text-capitalize">{item?.name}</h5>
                      <h2 className="fs-3">
                        <span className="theme-color fw-bold">
                          {item?.student_type === "adult" ? (
                            <>
                              Adult:{" "}
                              <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                              {item?.adult_price}
                            </>
                          ) : item?.student_type === "child" ? (
                            <>
                              Child:{" "}
                              <i className="fa fa-gbp" aria-hidden="true"></i>{" "}
                              {item?.child_price}
                            </>
                          ) : (
                            "NA"
                          )}
                        </span>
                      </h2>
                      <p className="text-center">
                        <small className="text-uppercase theme-color fs-6">
                          per annum
                        </small>
                      </p>
                      <ul className="mem-li">
                        <ReadMore>{item?.about}</ReadMore>
                      </ul>
                      <p className="text-center">
                        <label className="theme-color">Duration: </label>
                        {item?.no_of_days === "1"
                          ? `${item?.no_of_days} day`
                          : parseInt(item?.no_of_days) > 1
                          ? `${item?.no_of_days} days`
                          : "NA"}
                      </p>
                      {/* <span className="text-capitalize text-center theme-color cursor-pointer fw-bold">
                      <p className="text-center">terms & Condition apply</p>
                    </span> */}

                      {/* <span className="text-capitalize text-center cursor-pointer text-center">
                      <p>
                        <label className=" fw-bold theme-color">Student:</label>{" "}
                        {item?.student_details
                          ? `${item?.student_details?.first_name} ${item?.student_details?.last_name}`
                          : ""}
                      </p>
                    </span> */}

                      {/* {showTNC && (
                      <div className="bg-white p-3 mt-3">
                        <p>{item?.terms_and_condition}</p>
                      </div>
                    )} */}
                    </div>
                    {Number(user?.wallet) > 0 && (
                      <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 ps-4">
                        <div className="input-container term-checkbox ">
                          <input
                            type="checkbox"
                            id={`termCheckbox2${i}`}
                            name="tnc"
                            checked={i === selectedIndex && useWalletAmount}
                            // {...register("tnc")}
                            onChange={(e) => {
                              setUseWalletAmount(e.target.checked);
                              setSelectedIndex(i);
                            }}
                          />
                          <label htmlFor={`termCheckbox2${i}`}></label>
                          <span>Use wallet amount</span>
                        </div>
                      </div>
                    )}
                    {/* <div className="form-group col-sm-12 col-md-12 text-center">
                    <span className="fw-bold cursor-pointer">
                      By clicking below, you agree with the{" "}
                      <span
                        className="theme-color fw-bold"
                        onClick={() => {
                          navigate("/terms-and-condition/purchase membership");
                        }}
                      >
                        TERMS & CONDITION
                      </span>{" "}
                      for purchasing membership .
                    </span>
                  </div> */}
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 mb-4 text-center ">
                      <div className="input-container term-checkbox ">
                        <input
                          type="checkbox"
                          id={`${i}termCheckbox`}
                          name="tnc"
                          checked={selectedTncIndex === i && tnc}
                          // {...register("tnc")}
                          onChange={(e) => {
                            setSelectedTncIndex(i);
                            setTnc(e.target.checked);
                            if (e.target.checked) {
                              setErrors("");
                            }
                          }}
                        />
                        <label htmlFor={`${i}termCheckbox`}></label>
                        <span>
                          I accept the{" "}
                          <span
                            className="theme-color fw-bold"
                            onClick={() => {
                              navigate(
                                "/terms-and-condition/purchase membership"
                              );
                            }}
                          >
                            TERMS & CONDITION
                          </span>{" "}
                          for purchasing membership.
                        </span>
                        {errors && <div className="text-red">{errors}</div>}
                      </div>
                    </div>

                    {/* <div className="text-center mt-4">
                    <a
                      href={() => {
                        return false;
                      }}
                      className="fw-bold"
                    >
                      Buy Plan
                    </a>
                  </div> */}
                    {isRenewal && (
                      <button
                        type="button"
                        className="btn btn-submit fs-6 mb-1 w-100"
                      >
                        Renew Plan
                      </button>
                    )}
                    <button
                      type="button"
                      className="btn btn-submit fs-6 mb-1 w-100"
                      onClick={() => {
                        onBuy(item);
                      }}
                      disabled={loading}
                    >
                      {loading && selectedIndex === i ? (
                        <Loader />
                      ) : (
                        "Renew Plan"
                      )}
                    </button>
                  </div>
                );
              })
          ) : (
            <div className="text-center">No membership added.</div>
          )}
        </div>
      </div>
      <BuyMembershipPayment
        show={showPayment}
        handleShow={() => {
          setShowPayment(!showPayment);
        }}
        page="changePlan"
        reload={() => {}}
        showAnother={(val) => {
          console.log(val);
          navigate("/student");
        }}
      />
    </section>
  );
}

export default ChangePlan;
